import React, { Component } from 'react'

import school_logo from '../Assets/img/logo/MSMS-Vidhyalaya.jpg'
import school_logo1 from '../Assets/img/logo/IMG_9785.jpeg'
import school_logo2 from '../Assets/img/logo/IMG_10115.jpg'
import school_logo3 from '../Assets/img/logo/IMG_11547.jpg'
import school_logo4 from '../Assets/img/logo/IMG_13994.jpg'
import school_logo5 from '../Assets/img/logo/IMG_14004.jpeg'
import school_logo6 from '../Assets/img/logo/IMG_16015.jpg'
import school_logo7 from '../Assets/img/logo/IMG_16029.png'
import school_logo8 from '../Assets/img/logo/IMG_16794.jpg'
import school_logo9 from '../Assets/img/logo/IMG_20242.jpg'
import school_logo10 from '../Assets/img/logo/IMG_20248.jpg'
import school_logo11 from '../Assets/img/logo/jd-public-school.jpg'
import school_logo12 from '../Assets/img/logo/MSMS-Vidhyalaya.jpg'
import school_logo13 from '../Assets/img/logo/Orange_County.jpg'
import school_logo14 from '../Assets/img/logo/softtech.jpg'
import school_logo15 from '../Assets/img/logo/St-Thomas.jpg'
import school_logo16 from '../Assets/img/logo/vardhman-school.jpg'
export class Client extends Component {
    
    render() {
        let studentsDetails =[];
        let students = this.props.students;
        //https://stagingschool.myrkcl.com/upload/
        if (students.length) {
            studentsDetails = students.map((student) => {
              return (
                <div className="slide"><img src={'https://stagingschool.myrkcl.com/upload/'+student.school_logo}/></div>
                
              );
            });
          }
        return (
            <div>
                <div className="client" id="client">
                    <div className="container">
                        <div className="row py-4">
                            <div className="section-title pb-xl-4 pb-lg-4 pb-md-0">
                                <h2> Our clients</h2>
                            </div>
                            <div className="carousel-client my-xl-4 my-lg-4 my-md-2">
                                {studentsDetails}
                                <div className="slide"><img src={school_logo}/></div>
                                <div className="slide"><img src={school_logo1}/></div>
                                <div className="slide"><img src={school_logo2}/></div>
                                <div className="slide"><img src={school_logo3}/></div>
                                <div className="slide"><img src={school_logo4}/></div>
                                <div className="slide"><img src={school_logo5}/></div>
                                <div className="slide"><img src={school_logo6}/></div>
                                <div className="slide"><img src={school_logo7}/></div>
                                <div className="slide"><img src={school_logo8}/></div>
                                <div className="slide"><img src={school_logo9}/></div>
                                <div className="slide"><img src={school_logo10}/></div>
                                <div className="slide"><img src={school_logo11}/></div>
                                <div className="slide"><img src={school_logo12}/></div>
                                <div className="slide"><img src={school_logo13}/></div>
                                <div className="slide"><img src={school_logo14}/></div>
                                <div className="slide"><img src={school_logo15}/></div>
                                <div className="slide"><img src={school_logo16}/></div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Client

import React from "react"

import "../Assets/css/style.css";
import "../Assets/css/responsive.css";

import Header from './Header';

import Footer from './Footer';
import { MDBDataTable } from 'mdbreact';
//import { MDBContainer, MDBTable,MDBDataTable, MDBTableBody, MDBTableHead } from 'mdbreact';
const SalesPartner = () => {
  const data = {
    columns: [
      {
        label: 'District',
        field: 'district',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Tehsil',
        field: 'tehsil',
        sort: 'asc',
        width: 150
      },
      {
        label: 'ITGK Code',
        field: 'itgkcode',
        sort: 'asc',
        width: 150
      },
      {
        label: 'ITGK Name',
        field: 'itgkname',
        sort: 'asc',
        width: 250
      },
      {
        label: 'Mobile',
        field: 'mobile',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 200
      }
    ],
    rows: [
        {
        district: 'AJMER',
        tehsil: 'BIJAINAGAR',
        itgkcode: '12290148',
        itgkname: 'MAYUR COMPUTERS &amp; INFOTECH',
        mobile: '7737481811',
        email: 'neerajsrsharma@gmail.com',
        },
        {
        district: 'AJMER',
        tehsil: 'KISHANGARH',
        itgkcode: '12290349',
        itgkname: 'SHREEJI COMPUTER CENTER',
        mobile: '8432457652',
        email: 'sharma1234mukesh@gmail.com',
        },
        {
        district: 'AJMER',
        tehsil: 'NASIRABAD',
        itgkcode: '12290069',
        itgkname: 'DYNAMIC CAREER POINT',
        mobile: '9509678867',
        email: 'nsd_timerkcl@rediffmail.com',
        },
        {
        district: 'AJMER',
        tehsil: 'PISANGAN',
        itgkcode: '12290162',
        itgkname: 'CLICK COMPUTER INSTITUTE',
        mobile: '8302071731',
        email: 'dhruvangkumawat@gmail.com',
        },
        {
        district: 'AJMER',
        tehsil: 'PUSHKAR',
        itgkcode: '12290042',
        itgkname: 'UICT IT GYAN KENDRA',
        mobile: '9828782469',
        email: 'uict_pushkar2007@yahoo.com',
        },
        {
        district: 'AJMER',
        tehsil: 'PUSHKAR',
        itgkcode: '12290272',
        itgkname: 'RAHUL ACADEMY',
        mobile: '7291000209',
        email: 'rahulacademy7773@gmail.com',
        },
        {
        district: 'ALWAR',
        tehsil: 'ALWAR',
        itgkcode: '11290083',
        itgkname: 'SHARSHTI COMPUTERS',
        mobile: '9785809650',
        email: 'rssa216@gmail.com',
        },
        {
        district: 'ALWAR',
        tehsil: 'BEHROR',
        itgkcode: '11290050',
        itgkname: 'STARKING COMPUTER CENTER',
        mobile: '9413687773',
        email: 'strmanoj@gmail.com',
        },
        {
        district: 'ALWAR',
        tehsil: 'KATHOOMAR',
        itgkcode: '11290020',
        itgkname: 'RAJ COMPUTER ITGK',
        mobile: '9983572844',
        email: 'raj.pawan05@gmail.com',
        },
        {
        district: 'ALWAR',
        tehsil: 'KATHOOMAR',
        itgkcode: '11290348',
        itgkname: 'SATWARIYA COMPUTER CENTRE',
        mobile: '9785867245',
        email: 'prins321632@gmail.com',
        },
        {
        district: 'ALWAR',
        tehsil: 'KOTKASIM',
        itgkcode: '11290335',
        itgkname: 'DEV IT COLLEGE',
        mobile: '9462274887',
        email: 'sysk73@gmail.com',
        },
        {
        district: 'ALWAR',
        tehsil: 'MALAKHERA',
        itgkcode: '11290079',
        itgkname: 'VANSHIKA INSTITUTE OF INFORMATION TECHNOLOGY',
        mobile: '9782862670',
        email: 'viitmalakhera@gmail.com',
        },
        {
        district: 'ALWAR',
        tehsil: 'MUNDAWAR',
        itgkcode: '11290477',
        itgkname: 'AISHWARY COMPUTER TRAINING CENTER',
        mobile: '8696208090',
        email: 'hsy8696@gmail.com',
        },
        {
        district: 'ALWAR',
        tehsil: 'RAINI',
        itgkcode: '11290332',
        itgkname: 'GURUJI COMPUTER INSTITUTE',
        mobile: '9636654676',
        email: 'gurujico9636@gmail.com',
        },
        {
        district: 'ALWAR',
        tehsil: 'RAJGARH',
        itgkcode: '11290464',
        itgkname: 'SHRI BALAJI COMPUTER EDUCATION',
        mobile: '9680941516',
        email: 'ankitmishra20111999@gmail.com',
        },
        {
        district: 'ALWAR',
        tehsil: 'RAMGARH',
        itgkcode: '11290046',
        itgkname: 'PAUSCAL INSTITUTE OF COMPUTER SCIENCE',
        mobile: '9784306590',
        email: 'itgkpauscal@yahoo.in',
        },
        {
        district: 'ALWAR',
        tehsil: 'THANAGAZI',
        itgkcode: '11290260',
        itgkname: 'POOJA COMPUTER KNOWLEDGE CENTER',
        mobile: '9694838424',
        email: 'suresh.2013tng@gmail.com',
        },
        {
        district: 'ALWAR',
        tehsil: 'TIJARA',
        itgkcode: '11290039',
        itgkname: 'NICES COMPUTER CENTRE',
        mobile: '9982826300',
        email: 'vknices@gmail.com',
        },
        {
        district: 'BANSWARA',
        tehsil: 'BANSWARA',
        itgkcode: '17290005',
        itgkname: 'TARGET COMPUTERS',
        mobile: '9413529655',
        email: 'mmodi2222@yahoo.co.in',
        },
        {
        district: 'BANSWARA',
        tehsil: 'BANSWARA',
        itgkcode: '17290047',
        itgkname: 'BRILLIANT COMPUTER EDUCATION',
        mobile: '9602667171',
        email: 'rathore_lokesh@yahoo.co.in',
        },
        {
        district: 'BANSWARA',
        tehsil: 'BANSWARA',
        itgkcode: '17290124',
        itgkname: 'HP EDUCATION',
        mobile: '9414401525',
        email: 'hp9414401525@gmail.com',
        },
        {
        district: 'BANSWARA',
        tehsil: 'GANODA',
        itgkcode: '17290180',
        itgkname: 'RADHE COMPUTERS CENTER',
        mobile: '9166987875',
        email: 'aktrivedi0703@gmail.com',
        },
        {
        district: 'BARAN',
        tehsil: 'ATRU',
        itgkcode: '46290020',
        itgkname: 'JEET COMPUTER EDUCATION',
        mobile: '9829095775',
        email: 'jeetatru.rathore@yahoo.com',
        },
        {
        district: 'BARMER',
        tehsil: 'BALOTRA',
        itgkcode: '15290085',
        itgkname: 'M.NAVKAR INSTITUTE OF COMPUTER EDUCATION',
        mobile: '9667151826',
        email: 'nicebalotra@gmail.com',
        },
        {
        district: 'BARMER',
        tehsil: 'BALOTRA',
        itgkcode: '15290130',
        itgkname: 'KRISHNA EDUCATION GROUP',
        mobile: '6376161679',
        email: 'krishnaeg1008@gmail.com',
        },
        {
        district: 'BARMER',
        tehsil: 'BAYATU',
        itgkcode: '15290153',
        itgkname: 'VEER TEJA JI COMPUTER EDUCATION',
        mobile: '8107144590',
        email: 'veertejajicomputer90@gmail.com',
        },
        {
        district: 'BARMER',
        tehsil: 'GUDAMALANI',
        itgkcode: '15290123',
        itgkname: 'SHRI SHANIDEV INSTITUTE OF COMPUTER EDUCATION',
        mobile: '9828847036',
        email: 'rameshsuthar646@gmail.com',
        },
        {
        district: 'BARMER',
        tehsil: 'SHIV',
        itgkcode: '15290201',
        itgkname: 'BL INSTITUTE OF IT',
        mobile: '9680266662',
        email: 'blinstituteofit@gmail.com',
        },
        {
        district: 'BARMER',
        tehsil: 'SINDHARI',
        itgkcode: '15290099',
        itgkname: 'BALAJI COMPUTER',
        mobile: '7568152415',
        email: 'sci.jani1@gmail.com',
        },
        {
        district: 'BHARATPUR',
        tehsil: 'NAGAR',
        itgkcode: '13290171',
        itgkname: 'BABA IT COLLEGE',
        mobile: '8003520001',
        email: 'mkmanish509@gmail.com',
        },
        {
        district: 'BHARATPUR',
        tehsil: 'ROOPBAS',
        itgkcode: '13290125',
        itgkname: 'RUDAWAL ITGK',
        mobile: '7023612017',
        email: 'jhashivcharan@yahoo.com',
        },
        {
        district: 'BHILWARA',
        tehsil: 'BADNOR',
        itgkcode: '22290035',
        itgkname: 'GANPATI COMPUTER EDUCATION',
        mobile: '9660716586',
        email: 'shyamsikligar@gmail.com',
        },
        {
        district: 'BHILWARA',
        tehsil: 'BANEDA',
        itgkcode: '22290168',
        itgkname: 'HI-TECH COMPUTER EDUCATION',
        mobile: '7597133876',
        email: 'sharmalucky163@gmail.com',
        },
        {
        district: 'BHILWARA',
        tehsil: 'BHILWARA',
        itgkcode: '22290115',
        itgkname: 'MAHALAXMI ACADEMY',
        mobile: '9784283986',
        email: 'govindkhatik8692@gmail.com',
        },
        {
        district: 'BHILWARA',
        tehsil: 'BHILWARA',
        itgkcode: '22290149',
        itgkname: 'RAJ SHREE COPUTERS',
        mobile: '9799739697',
        email: 'rajnuwal@gmail.com',
        },
        {
        district: 'BHILWARA',
        tehsil: 'BIJOLIYAKALAN',
        itgkcode: '22290143',
        itgkname: 'SHIVAM EDUCATION',
        mobile: '9214973500',
        email: 'shivameducation818@gmail.com',
        },
        {
        district: 'BHILWARA',
        tehsil: 'HAMIRGARH',
        itgkcode: '22290111',
        itgkname: 'SAMRAT COMPUTER AND IT GYAN KENDRA',
        mobile: '9587777976',
        email: 'dodiya.shakti@gmail.com',
        },
        {
        district: 'BHILWARA',
        tehsil: 'HURDA',
        itgkcode: '22290070',
        itgkname: 'SHRI VINAYAK COMPUTER',
        mobile: '9414332261',
        email: 'vinayakcomputergbp@yahoo.in',
        },
        {
        district: 'BHILWARA',
        tehsil: 'MANDAL',
        itgkcode: '22290082',
        itgkname: 'UNIQUE COMPUTER CENTRE',
        mobile: '7742017097',
        email: 'ucc.maya@gmail.com',
        },
        {
        district: 'BHILWARA',
        tehsil: 'MANDALGARH',
        itgkcode: '22290099',
        itgkname: 'SVCSM COMPUTER EDUCATION MISSION',
        mobile: '9351715003',
        email: 'sandeepaggrwal90@gmail.com',
        },
        {
        district: 'BHILWARA',
        tehsil: 'PHULIA KALAN',
        itgkcode: '22290204',
        itgkname: 'JMD COMPUTER',
        mobile: '8107486275',
        email: 'jmdcomputerphuliakalan@gmail.com',
        },
        {
        district: 'BHILWARA',
        tehsil: 'RAIPUR',
        itgkcode: '22290213',
        itgkname: 'GENPACT COMPUTERS',
        mobile: '9694648196',
        email: 'genpactcomputers@gmail.com',
        },
        {
        district: 'BHILWARA',
        tehsil: 'SAHADA',
        itgkcode: '22290223',
        itgkname: 'SHREE S.S.COMPUTERS',
        mobile: '9587916661',
        email: 'shreessgpr@gmail.com',
        },
        {
        district: 'BHILWARA',
        tehsil: 'SHAHPURA',
        itgkcode: '22290076',
        itgkname: 'SIDDHARTH COMPUTER INSTITUTE',
        mobile: '8769735989',
        email: 'siddharthcompushp@gmail.com',
        },
        {
        district: 'BHILWARA',
        tehsil: 'SHAHPURA',
        itgkcode: '22290086',
        itgkname: 'MAA COMPUTER SHIKSHAN SANSTHAN',
        mobile: '9785485299',
        email: 'mcss041@gmail.com',
        },
        {
        district: 'BIKANER',
        tehsil: 'BIKANER',
        itgkcode: '14290166',
        itgkname: 'SHREE DEV COMPUTER',
        mobile: '8949399852',
        email: 'dev.rkcl@gmail.com',
        },
        {
        district: 'BIKANER',
        tehsil: 'DUNGARGARH',
        itgkcode: '14290042',
        itgkname: 'RAJASTHAN COMPUTER CENTRE',
        mobile: '9928484679',
        email: 'rajasthancomputer@yahoo.in',
        },
        {
        district: 'BIKANER',
        tehsil: 'KOLAYAT',
        itgkcode: '14290205',
        itgkname: 'UDAY SKILL ACADEMY, KOLAYAT',
        mobile: '9799104057',
        email: 'satyaveer171995@gmail.com',
        },
        {
        district: 'BUNDI',
        tehsil: 'HINDOLI',
        itgkcode: '16290061',
        itgkname: 'GLOBAL INSTITUTE OF INFORMATION TECHNOLOGY',
        mobile: '9799162850',
        email: 'gopilalsaini9@gmail.com',
        },
        {
        district: 'BUNDI',
        tehsil: 'HINDOLI',
        itgkcode: '16290092',
        itgkname: 'SWAMI VIVEKANANDA IT CENTER',
        mobile: '7240161191',
        email: 'bhagwanprajapat24696@gmail.com',
        },
        {
        district: 'BUNDI',
        tehsil: 'INDRAGARH',
        itgkcode: '16290059',
        itgkname: 'DEV COMPUTER EDUCATION',
        mobile: '9928229646',
        email: 'dev.computer123@gmail.com',
        },
        {
        district: 'BUNDI',
        tehsil: 'INDRAGARH',
        itgkcode: '16290099',
        itgkname: 'SHIV COMPUTER EDUCATION',
        mobile: '9929106096',
        email: 'jk570027@gmail.com',
        },
        {
        district: 'CHITTAURGARH',
        tehsil: 'BADESAR',
        itgkcode: '27290101',
        itgkname: 'SHREE BHAIRAV COMPUTER INSTITUTE',
        mobile: '7737902580',
        email: 'kishanradha880@gmail.com',
        },
        {
        district: 'CHITTAURGARH',
        tehsil: 'BADI SADRI',
        itgkcode: '27290084',
        itgkname: 'EVERSHINE COMPUTER EDUCATION',
        mobile: '9887765777',
        email: 'EVERSHINEEDUCATION777@GMAIL.COM',
        },
        {
        district: 'CHITTAURGARH',
        tehsil: 'BASSI',
        itgkcode: '27290132',
        itgkname: 'GENIUS INSTITUTE OF IT EDUCATION',
        mobile: '7424978007',
        email: 'geniusitssolution007@gmail.com',
        },
        {
        district: 'CHITTAURGARH',
        tehsil: 'BEGUN',
        itgkcode: '27290181',
        itgkname: 'SOLUTION POINT',
        mobile: '9588967088',
        email: 'meenacs76@gmail.com',
        },
        {
        district: 'CHITTAURGARH',
        tehsil: 'CHITTAURGARH',
        itgkcode: '27290009',
        itgkname: 'SHARMA INFOTECH',
        mobile: '9950072039',
        email: 'rscitsharma@gmail.com',
        },
        {
        district: 'CHITTAURGARH',
        tehsil: 'GANGRAR',
        itgkcode: '27290134',
        itgkname: 'JAI SHREE CAREER INSTITUTE',
        mobile: '8875787040',
        email: 'jaishreeclassesgangrar@gmail.com',
        },
        {
        district: 'CHITTAURGARH',
        tehsil: 'NIMBAHEDA',
        itgkcode: '27290047',
        itgkname: 'MPM COMPUTER EDUCATION',
        mobile: '9252013385',
        email: 'nbhmpm@gmail.com',
        },
        {
        district: 'CHURU',
        tehsil: 'RAJGARH',
        itgkcode: '24290006',
        itgkname: 'IICE COMPUTER EDUCATION,SADULPUR,CHURU',
        mobile: '9887078875',
        email: 'iicesadulpur@yahoo.com',
        },
        {
        district: 'CHURU',
        tehsil: 'TARANAGAR',
        itgkcode: '24290022',
        itgkname: 'CHORDIA INFOTECH',
        mobile: '9414894278',
        email: 'puneet_mcc@yahoo.in',
        },
        {
        district: 'DAUSA',
        tehsil: 'BASWA',
        itgkcode: '51290060',
        itgkname: 'ROYAL COMPUTER CENTER',
        mobile: '9414438431',
        email: 'kamlesh_saini2001@yahoo.com',
        },
        {
        district: 'DAUSA',
        tehsil: 'BASWA',
        itgkcode: '51290131',
        itgkname: 'JAGDAMBA INSTITUTE OF INFORMATION TECHNOLOGY',
        mobile: '9024388963',
        email: 'jiitbandikui@gmail.com',
        },
        {
        district: 'DAUSA',
        tehsil: 'DAUSA',
        itgkcode: '51290039',
        itgkname: 'EXCELLENT ITGK',
        mobile: '9887018205',
        email: 'excellentdausa@gmail.com',
        },
        {
        district: 'DAUSA',
        tehsil: 'DAUSA',
        itgkcode: '51290115',
        itgkname: 'SAKSHI COMPUTERS',
        mobile: '9509921465',
        email: 'satishkumarsharma10@gmail.com',
        },
        {
        district: 'DAUSA',
        tehsil: 'NANAGAL RAJAWATAN',
        itgkcode: '51290085',
        itgkname: 'OMNS COLLEGE',
        mobile: '9829225939',
        email: 'omnscollege@gmail.com',
        },
        {
        district: 'DAUSA',
        tehsil: 'SIKRAI',
        itgkcode: '51290068',
        itgkname: 'EXPRESS COMPUTERS',
        mobile: '8619854051',
        email: 'rsjaiit0@gmail.com',
        },
        {
        district: 'DAUSA',
        tehsil: 'SIKRAI',
        itgkcode: '51290123',
        itgkname: 'TULSI RAM ADARSH VIDHYA MANDIR',
        mobile: '9784710651',
        email: 'sainistone@gmail.com',
        },
        {
        district: 'DHAULPUR',
        tehsil: 'RAJAKHEDA',
        itgkcode: '23290046',
        itgkname: 'PARUL COMPUTER EDUCATION',
        mobile: '9351518208',
        email: 'hariomsingh81@yahoo.com',
        },
        {
        district: 'DHAULPUR',
        tehsil: 'SAIPEU',
        itgkcode: '23290083',
        itgkname: 'GAURAV INSTITUTE&nbsp; COMPUTER CENTER',
        mobile: '9785743931',
        email: 'tamorygaurav@gmail.com',
        },
        {
        district: 'GANGANAGAR',
        tehsil: 'RAISINGHNAGAR',
        itgkcode: '34290009',
        itgkname: 'M.P.S.COMPUTERS',
        mobile: '9828579002',
        email: 'aptechrais_ngr@yahoo.com',
        },
        {
        district: 'GANGANAGAR',
        tehsil: 'RAISINGHNAGAR',
        itgkcode: '34290034',
        itgkname: 'PAREEK INFORMATION TECHNOLOGY COLLEGE',
        mobile: '9460561860',
        email: 'pareek.computer@yahoo.com',
        },
        {
        district: 'GANGANAGAR',
        tehsil: 'SRIGANGANAGAR',
        itgkcode: '34290066',
        itgkname: 'GEM EDUCATION AND CONSULTANCY',
        mobile: '9309477709',
        email: 'hancysumit@gmail.com',
        },
        {
        district: 'GANGANAGAR',
        tehsil: 'SRIGANGANAGAR',
        itgkcode: '34290252',
        itgkname: 'BRILIANT COMPUTERS',
        mobile: '9694600029',
        email: '64lnpsandeep29@gmail.com',
        },
        {
        district: 'GANGANAGAR',
        tehsil: 'VIJAYNAGAR',
        itgkcode: '34290221',
        itgkname: 'DYNASTY INSTITUTE OF IT',
        mobile: '9460393330',
        email: 'niks.aadi@gmail.com',
        },
        {
        district: 'HANUMANGARH',
        tehsil: 'BHADRA',
        itgkcode: '44290052',
        itgkname: 'DR. S.RADHAKRISHNAN SHIKSHAN PARSHIKSHAN SANSTHAN-',
        mobile: '9414579831',
        email: 'schander94@gmail.com',
        },
        {
        district: 'HANUMANGARH',
        tehsil: 'HANUMANGARH',
        itgkcode: '44290158',
        itgkname: 'SHRI GURU NANAK COMPUTER INFO.TEH.SANATHAN',
        mobile: '9252765886',
        email: 'sgnnsdc@gmail.com',
        },
        {
        district: 'HANUMANGARH',
        tehsil: 'NOHAR',
        itgkcode: '44290328',
        itgkname: 'TALENT CLASSES',
        mobile: '7073546915',
        email: 'saiamit0707@gmail.com',
        },
        {
        district: 'HANUMANGARH',
        tehsil: 'RAWATSAR',
        itgkcode: '44290073',
        itgkname: 'MICROSYS INFOTECH EDUCATION INSTITUTE',
        mobile: '9660968961',
        email: 'microsysrkcl@gmail.com',
        },
        {
        district: 'HANUMANGARH',
        tehsil: 'SANGARIYA',
        itgkcode: '44290268',
        itgkname: 'PERFECT COMPUTER CENTRE',
        mobile: '9667093655',
        email: 'sanjananarula1@gmail.com',
        },
        {
        district: 'HANUMANGARH',
        tehsil: 'SANGARIYA',
        itgkcode: '44290272',
        itgkname: 'TAKSHILA',
        mobile: '9413176272',
        email: 'takshilaglw@gmail.com',
        },
        {
        district: 'JAIPUR',
        tehsil: 'AMER',
        itgkcode: '21290159',
        itgkname: 'WAHE GURU COMPUTER LEARNING CENTER',
        mobile: '9929343901',
        email: 'navsoft.shp@gmail.com',
        },
        {
        district: 'JAIPUR',
        tehsil: 'ANDHI',
        itgkcode: '61290020',
        itgkname: 'GENIUS INSTITUTE OF TECHNOLOGY (GIT)',
        mobile: '8107834183',
        email: 'geniuscomputers52@yahoo.in',
        },
        {
        district: 'JAIPUR',
        tehsil: 'CHAKSU',
        itgkcode: '81290056',
        itgkname: 'YOUTH EMPOWERMENT SOCIETY',
        mobile: '8118878286',
        email: 'yessgc1@gmail.com',
        },
        {
        district: 'JAIPUR',
        tehsil: 'CHOMU',
        itgkcode: '71290022',
        itgkname: 'AIIT INSTITUTE OF COMPUTER EDUCATION',
        mobile: '9928703744',
        email: 'mohanjakhar111@gmail.com',
        },
        {
        district: 'JAIPUR',
        tehsil: 'JAIPUR',
        itgkcode: '21290375',
        itgkname: 'DICEST EDUCATION PVT LTD',
        mobile: '9783277847',
        email: 'dicesteducation@gmail.com',
        },
        {
        district: 'JAIPUR',
        tehsil: 'JAIPUR',
        itgkcode: '81290009',
        itgkname: 'COMPUTER GYAN',
        mobile: '9982541511',
        email: 'COMPUTERGYANE@GMAIL.COM',
        },
        {
        district: 'JAIPUR',
        tehsil: 'KOTKHAUDA',
        itgkcode: '21290826',
        itgkname: 'FOREVER EDUCATION GROUP',
        mobile: '9783109110',
        email: 'cmjangid1@gmail.com',
        },
        {
        district: 'JAIPUR',
        tehsil: 'KOTPUTLI',
        itgkcode: '21290337',
        itgkname: 'HY-TAKE',
        mobile: '9314207675',
        email: 'jatbhupsingh@gmail.com',
        },
        {
        district: 'JAIPUR',
        tehsil: 'KOTPUTLI',
        itgkcode: '21290788',
        itgkname: 'DIGITAL COMPUTER TECHNOLOGY',
        mobile: '9649911993',
        email: 'dctkotputli@gmail.com',
        },
        {
        district: 'JAIPUR',
        tehsil: 'PAOTA',
        itgkcode: '21291179',
        itgkname: 'KRISH COMPUTER PAOTA',
        mobile: '9252692518',
        email: 'Krishcomputerpaota@gmail.com',
        },
        {
        district: 'JAIPUR',
        tehsil: 'PHULERA',
        itgkcode: '21290428',
        itgkname: 'TRIVENI COMPUTER EDUCATION CENTER',
        mobile: '9214013159',
        email: 'dilipsuroliya@gmail.com',
        },
        {
        district: 'JAIPUR',
        tehsil: 'SHAHPURA',
        itgkcode: '71290069',
        itgkname: 'BABA BHAGWANDAS IT CENTRE',
        mobile: '9829428964',
        email: 'rajdadrwal@gmail.com',
        },
        {
        district: 'JAIPUR',
        tehsil: 'TOONGA',
        itgkcode: '21290152',
        itgkname: 'BATWAL INFOTECH ACADEMY',
        mobile: '7737218090',
        email: 'jvijetan@gmail.com',
        },
        {
        district: 'JAIPUR',
        tehsil: 'TOONGA',
        itgkcode: '21290382',
        itgkname: 'ROYAL COMPUTER INSTITUTE',
        mobile: '9828582101',
        email: 'cool.vinod2@gmail.com',
        },
        {
        district: 'JAIPUR',
        tehsil: 'VIRATNAGAR',
        itgkcode: '21290806',
        itgkname: 'SURYA COMPUTER AND ENGINEERING INSTITUTE',
        mobile: '8769527206',
        email: 'suryacomputervirat21@gmail.com',
        },
        {
        district: 'JAISALMER',
        tehsil: 'JAISALMER',
        itgkcode: '25290002',
        itgkname: 'JAGTAMBA INFOTECH,JAISALMER',
        mobile: '9414763904',
        email: 'jicejsm@gmail.com',
        },
        {
        district: 'JAISALMER',
        tehsil: 'JAISALMER',
        itgkcode: '25290020',
        itgkname: 'KARTIK INSTITUTE OF INFORMATION TECHNOLOGY',
        mobile: '9799796483',
        email: 'sampublicschool27@gmail.com',
        },
        {
        district: 'JALOR',
        tehsil: 'SAYLA',
        itgkcode: '35290033',
        itgkname: 'SUNSHINE INSTITUTE SAYLA',
        mobile: '9521021102',
        email: 'sunshineinstitute2019@gmail.com',
        },
        {
        district: 'JHALAWAR',
        tehsil: 'JHALRAPATAN',
        itgkcode: '26290005',
        itgkname: 'TOP CAREER COMPUTERS',
        mobile: '9414193620',
        email: 'topcareercomputers@gmail.com',
        },
        {
        district: 'JHALAWAR',
        tehsil: 'JHALRAPATAN',
        itgkcode: '26290103',
        itgkname: 'A TOP COMPUTER CLASSES',
        mobile: '9829542779',
        email: 'ARUNAJMERAM99@GMAIL.COM',
        },
        {
        district: 'JHALAWAR',
        tehsil: 'PACHPAHAD',
        itgkcode: '26290156',
        itgkname: 'CHAHAT COMPUTERS',
        mobile: '9001747666',
        email: 'chahetpareta2525@gmail.com',
        },
        {
        district: 'JHUNJHUNU',
        tehsil: 'BUHANA',
        itgkcode: '31290106',
        itgkname: 'IT EXCELLENT',
        mobile: '9887293028',
        email: 'itexcellent@rediffmail.com',
        },
        {
        district: 'JHUNJHUNU',
        tehsil: 'CHIDAWA',
        itgkcode: '31290073',
        itgkname: 'RAJEEV GANDHI COMPUTER PRASAR MISSION MANDRELLA',
        mobile: '9829237799',
        email: 'imranpilani@gmail.com',
        },
        {
        district: 'JHUNJHUNU',
        tehsil: 'MALSISAR',
        itgkcode: '31290122',
        itgkname: 'OXFORD IT GK&nbsp; ACADEMY',
        mobile: '8432526171',
        email: 'surendra.rathore61@gmail.com',
        },
        {
        district: 'JODHPUR',
        tehsil: 'BALESAR',
        itgkcode: '45290233',
        itgkname: 'GAJANARAY COMPUTER SANSTHAN',
        mobile: '7665058215',
        email: 'rkpbalesar2014@gmail.com',
        },
        {
        district: 'JODHPUR',
        tehsil: 'BALESAR',
        itgkcode: '45290482',
        itgkname: 'A-ONE COMPUTER COACHING CENTER',
        mobile: '7732947994',
        email: 'aonecomputer02016@gmail.com',
        },
        {
        district: 'JODHPUR',
        tehsil: 'BAORI',
        itgkcode: '45290182',
        itgkname: 'SUNCITY INSTITUTE',
        mobile: '9660812831',
        email: 'mksolanki07@gmail.com',
        },
        {
        district: 'JODHPUR',
        tehsil: 'BAORI',
        itgkcode: '45290207',
        itgkname: 'NAVNEET COMPUTER SANSTHAN',
        mobile: '9079742645',
        email: 'nsprajapatncs@gmail.com',
        },
        {
        district: 'JODHPUR',
        tehsil: 'BHOPALGARH',
        itgkcode: '45290013',
        itgkname: 'PROGRESSIVE COMPUTER &amp; IT GYAN KENDRA',
        mobile: '9414563902',
        email: 'progcomp@yahoo.com',
        },
        {
        district: 'JODHPUR',
        tehsil: 'FALODI',
        itgkcode: '45290021',
        itgkname: 'CYBERTECH',
        mobile: '9414410448',
        email: 'cybertech.phalodi@gmail.com',
        },
        {
        district: 'JODHPUR',
        tehsil: 'FALODI',
        itgkcode: '45290274',
        itgkname: 'GOYAL EDUCATION INSTITUTE',
        mobile: '9829310312',
        email: 'goyaledu.16@gmail.com',
        },
        {
        district: 'JODHPUR',
        tehsil: 'JODHPUR',
        itgkcode: '45290205',
        itgkname: 'SUVIJAY INFOTECH',
        mobile: '9314487901',
        email: 'laxmansinghgehlot15@gmail.com',
        },
        {
        district: 'JODHPUR',
        tehsil: 'LOHAWAT',
        itgkcode: '45290187',
        itgkname: 'VIVEKANAND COMPUTER ACADEMY AND EDUCATION SANSTHAN',
        mobile: '9667992872',
        email: 'suman.bhanwal.com@gmail.com',
        },
        {
        district: 'JODHPUR',
        tehsil: 'LUNI',
        itgkcode: '45290143',
        itgkname: 'PREMANAND COMPUTER PRASHIKSHAN SANSTHAN',
        mobile: '9461292163',
        email: 'pcps.computer@gmail.com',
        },
        {
        district: 'JODHPUR',
        tehsil: 'OSIYAN',
        itgkcode: '45290432',
        itgkname: 'LAKSHYA COMPUTERS AND COMPETITION COACHING',
        mobile: '8107518336',
        email: 'tilokbhakar333@gmail.com',
        },
        {
        district: 'JODHPUR',
        tehsil: 'PIPAR CITY',
        itgkcode: '45290136',
        itgkname: 'MARWAR COMPUTER ACADEMY',
        mobile: '8058751077',
        email: 'ramswroop169@gmail.com',
        },
        {
        district: 'KARAULI',
        tehsil: 'NADOTI',
        itgkcode: '43290025',
        itgkname: 'SWAMI VIVEKANAND COMPUTER ACADEMY',
        mobile: '9829203222',
        email: 'svcomputeracademy2020@gmail.com',
        },
        {
        district: 'KOTA',
        tehsil: 'LADPURA',
        itgkcode: '36290097',
        itgkname: 'SAWANT COMPUTER',
        mobile: '9664261342',
        email: 'tonydesire1@gmail.com',
        },
        {
        district: 'KOTA',
        tehsil: 'LADPURA',
        itgkcode: '36290104',
        itgkname: 'MCM COMPUTER EDUCATION INSTITUTE',
        mobile: '9460173034',
        email: 'mcmcomputeredu.kota@gmail.com',
        },
        {
        district: 'KOTA',
        tehsil: 'LADPURA',
        itgkcode: '36290134',
        itgkname: 'KARTIKAY SHIKSHAN SANSTHAN',
        mobile: '9414221999',
        email: 'mahendrameena5170@gmail.com',
        },
        {
        district: 'KOTA',
        tehsil: 'LADPURA',
        itgkcode: '36290208',
        itgkname: 'SHREE NATH COMPUTER INSTITUTE',
        mobile: '7877779454',
        email: 'gautamvipin49@gmail.com',
        },
        {
        district: 'KOTA',
        tehsil: 'PIPALDA',
        itgkcode: '36290170',
        itgkname: 'SHRI SAI COMPUTER EDUCATION ITAWA',
        mobile: '9983998358',
        email: 'saicomputer160@gmail.com',
        },
        {
        district: 'KOTA',
        tehsil: 'PIPALDA',
        itgkcode: '36290267',
        itgkname: 'RADHIKA COMPUTER INSTITUTE',
        mobile: '8740928219',
        email: 'rajendranagar726@gmail.com',
        },
        {
        district: 'KOTA',
        tehsil: 'PIPALDA',
        itgkcode: '36290290',
        itgkname: 'PRIYANKA COMPUTER EDUCATION',
        mobile: '8562053665',
        email: 'vr02835@gmail.com',
        },
        {
        district: 'KOTA',
        tehsil: 'RAMGANJMANDI',
        itgkcode: '36290006',
        itgkname: 'EXPLORER COLLEGE FOR PROFESSIONAL STUDIES',
        mobile: '9460122736',
        email: 'explorer.college@gmail.com',
        },
        {
        district: 'KOTA',
        tehsil: 'RAMGANJMANDI',
        itgkcode: '36290154',
        itgkname: 'JAY JINENDRA COMPUTER CENTRE',
        mobile: '9001453591',
        email: 'mapadmawati1008@gmail.com',
        },
        {
        district: 'NAGAUR',
        tehsil: 'DEEDWANA',
        itgkcode: '32290141',
        itgkname: 'BAGRI COMPUTER CENTRE',
        mobile: '9887453700',
        email: 'bablusaini07@gmail.com',
        },
        {
        district: 'NAGAUR',
        tehsil: 'DEGANA',
        itgkcode: '32290182',
        itgkname: 'JAY SHREE COLLEGE',
        mobile: '9828944314',
        email: 'jayshreedegana@gmail.com',
        },
        {
        district: 'NAGAUR',
        tehsil: 'JAYAL',
        itgkcode: '32290114',
        itgkname: 'SUMIT COMPUTER CENTER',
        mobile: '9785219734',
        email: 'sumitcomps@gmail.com',
        },
        {
        district: 'NAGAUR',
        tehsil: 'JAYAL',
        itgkcode: '32290270',
        itgkname: 'SUNRISE EDUCATION COMPUTER CENTER',
        mobile: '9782121578',
        email: 'sunrisecomputercenterjayal@gmail.com',
        },
        {
        district: 'NAGAUR',
        tehsil: 'KHEENWSAR',
        itgkcode: '32290127',
        itgkname: 'RAJSHREE COMPUTER',
        mobile: '9828246440',
        email: 'rsc.khimsar@gmail.com',
        },
        {
        district: 'NAGAUR',
        tehsil: 'LADANU',
        itgkcode: '32290030',
        itgkname: 'SHIKSHA FUTURE POINT',
        mobile: '9351131051',
        email: 'sfpladnun@gmail.com',
        },
        {
        district: 'NAGAUR',
        tehsil: 'LADANU',
        itgkcode: '32290092',
        itgkname: 'BAGRI COMPUTER CENTER',
        mobile: '9982819577',
        email: 'sainib11@yahoo.com',
        },
        {
        district: 'NAGAUR',
        tehsil: 'MAKRANA',
        itgkcode: '32290173',
        itgkname: 'ADVANCE COMPUTER EDUCATION',
        mobile: '8239530027',
        email: 'acebudsu@gmail.com',
        },
        {
        district: 'NAGAUR',
        tehsil: 'MERATACITY',
        itgkcode: '32290027',
        itgkname: 'JAI SHREE COMPUTER',
        mobile: '9413132093',
        email: 'gs_mtc@yahoo.com',
        },
        {
        district: 'NAGAUR',
        tehsil: 'MUNDWA',
        itgkcode: '32290168',
        itgkname: 'DEEKSHA CLASSES',
        mobile: '7737301699',
        email: 'hemanttailor9621@gmail.com',
        },
        {
        district: 'NAGAUR',
        tehsil: 'MUNDWA',
        itgkcode: '32290232',
        itgkname: 'LORD SHIVA ITGK',
        mobile: '9772218966',
        email: 'asawariram8966@gmail.com',
        },
        {
        district: 'NAGAUR',
        tehsil: 'NAWAN',
        itgkcode: '32290073',
        itgkname: 'SHREE SHYAM COMPUTER EDUCATION',
        mobile: '9799007898',
        email: 'sscejks@gmail.com',
        },
        {
        district: 'NAGAUR',
        tehsil: 'PARBATSAR',
        itgkcode: '32290025',
        itgkname: 'CIT COMPUTER CENTRE',
        mobile: '9785334538',
        email: 'cit.computer94@gmail.com',
        },
        {
        district: 'PALI',
        tehsil: 'PALI',
        itgkcode: '55290165',
        itgkname: 'SIRVI COMPUTER CENTER',
        mobile: '9983004333',
        email: 'sirvicomputer@gmail.com',
        },
        {
        district: 'PALI',
        tehsil: 'PALI',
        itgkcode: '55290220',
        itgkname: 'CP COMPUTER EDUCATION',
        mobile: '8949809182',
        email: 'cpsharma42@yahoo.in',
        },
        {
        district: 'PALI',
        tehsil: 'RANI',
        itgkcode: '55290094',
        itgkname: 'A-SHINE COMPUTER ACEDEMY',
        mobile: '9468689806',
        email: 'kamlesh.narwa@gmail.com',
        },
        {
        district: 'PALI',
        tehsil: 'RANI',
        itgkcode: '55290149',
        itgkname: 'SATYAM EDUCATIONAL INSTITUTE',
        mobile: '9782211027',
        email: 'satyaminstituterani@gmail.com',
        },
        {
        district: 'PALI',
        tehsil: 'SOJAT',
        itgkcode: '55290028',
        itgkname: 'SHREE COMP.',
        mobile: '9460904373',
        email: 'nidhish_dave@yahoo.com',
        },
        {
        district: 'PALI',
        tehsil: 'SOJAT',
        itgkcode: '55290053',
        itgkname: 'RAJ COMPUTER EDUCATION CENTER',
        mobile: '9887581818',
        email: 'rajveer4820@gmail.com',
        },
        {
        district: 'PRATAPGARH',
        tehsil: 'CHHOTI SADARI',
        itgkcode: '67290078',
        itgkname: 'EVERSHINE',
        mobile: '9887765777',
        email: 'evershineeducation777@gmail.com',
        },
        {
        district: 'PRATAPGARH',
        tehsil: 'PIPALKHUNTH',
        itgkcode: '67290055',
        itgkname: 'PRINCE COMPUTER',
        mobile: '9783024538',
        email: 'amitsoni978@gmail.com',
        },
        {
        district: 'PRATAPGARH',
        tehsil: 'PRATAPGARH',
        itgkcode: '67290046',
        itgkname: 'PRIMROSE COMPUTER EDUCATION',
        mobile: '8107917143',
        email: 'mukeshgayari90@gmail.com',
        },
        {
        district: 'PRATAPGARH',
        tehsil: 'PRATAPGARH',
        itgkcode: '67290056',
        itgkname: 'DISHA CONSULTANTS',
        mobile: '9887785795',
        email: 'mali.kapil@yahoo.com',
        },
        {
        district: 'RAJSAMAND',
        tehsil: 'RAJSAMAND',
        itgkcode: '57290006',
        itgkname: 'SHINE COMPUTERS',
        mobile: '9829445469',
        email: 'khushishine@hotmail.com',
        },
        {
        district: 'RAJSAMAND',
        tehsil: 'RELMAGRA',
        itgkcode: '57290005',
        itgkname: 'SITE TECHNICAL EDUCATION',
        mobile: '9828827326',
        email: 'site.rajasthan@gmail.com',
        },
        {
        district: 'SIKAR',
        tehsil: 'DANTARAMGARH',
        itgkcode: '41290150',
        itgkname: 'RAJASTHAN COMPUTER CENTER KHATUSHYAM JI (SIKAR)',
        mobile: '9929764576',
        email: 'rajendrabhadhala@gmail.com',
        },
        {
        district: 'SIKAR',
        tehsil: 'DANTARAMGARH',
        itgkcode: '41290152',
        itgkname: 'SHREE RAM COMPUTER EDUCATION INSTITUTE',
        mobile: '9983137601',
        email: 'skumar.kumar111@gmail.com',
        },
        {
        district: 'SIKAR',
        tehsil: 'DANTARAMGARH',
        itgkcode: '41290242',
        itgkname: 'GURUKRIPA&nbsp; COMPUTER ACADEMY RANOLI',
        mobile: '9887412852',
        email: 'gurukulranoli@gmail.com',
        },
        {
        district: 'SIKAR',
        tehsil: 'FATEHPUR',
        itgkcode: '41290218',
        itgkname: 'TTCE',
        mobile: '9828213366',
        email: 'TTCEFATEHPUR@REDIFFMAIL.COM',
        },
        {
        district: 'SIKAR',
        tehsil: 'KHANDELA',
        itgkcode: '41290363',
        itgkname: 'SAINI COMPUTER CENTER',
        mobile: '9782533332',
        email: 'kanwatmukesh32@gmail.com',
        },
        {
        district: 'SIKAR',
        tehsil: 'LAXMANGARH',
        itgkcode: '41290111',
        itgkname: 'SARASWATI COMPUTER EDUCATION',
        mobile: '9610557204',
        email: 'saraswati.laxmangarh@gmail.com',
        },
        {
        district: 'SIKAR',
        tehsil: 'RAMGARH SHEKHAWATI',
        itgkcode: '41290124',
        itgkname: 'SUCCESS COMPUTER EDUCATION',
        mobile: '9928457856',
        email: 'javedqureshi99@gmail.com',
        },
        {
        district: 'SIKAR',
        tehsil: 'SHRIMADHOPUR',
        itgkcode: '41290362',
        itgkname: 'NEW TRIVENI INFOTECH',
        mobile: '9928474388',
        email: 'newtriveniinfo@gmail.com',
        },
        {
        district: 'SIKAR',
        tehsil: 'SIKAR',
        itgkcode: '41290035',
        itgkname: 'MICROSEFT COMPUTER CENTER',
        mobile: '9509680333',
        email: 'microseftsikar@gmail.com',
        },
        {
        district: 'SIKAR',
        tehsil: 'SIKAR',
        itgkcode: '41290200',
        itgkname: 'ABHAY IT GYAN KANDRA',
        mobile: '9667071008',
        email: 'abhayitgk@gmail.com',
        },
        {
        district: 'SIKAR',
        tehsil: 'SIKAR',
        itgkcode: '41290325',
        itgkname: 'KISHAN COMPUTER CENTER',
        mobile: '9784009847',
        email: 'mamrajbagriya@gmail.com',
        },
        {
        district: 'SIROHI',
        tehsil: 'PINDWADA',
        itgkcode: '65290018',
        itgkname: 'COMPUWARE SOFTWARE ACADEMY (A UNIT OF SAI GURUKUL)',
        mobile: '9352241418',
        email: 'rohit_rawal.pindwara@yahoo.com',
        },
        {
        district: 'TONK',
        tehsil: 'MALPURA',
        itgkcode: '42290060',
        itgkname: 'SHREEJI COMPUTER EDUCATION CENTER',
        mobile: '8432629429',
        email: 'shreejicomputer9@gmail.com',
        },
        {
        district: 'TONK',
        tehsil: 'NIWAI',
        itgkcode: '42290026',
        itgkname: 'SHARMA INSTITUTE OF TECHNOLOGICAL EDUCATION(SITE)',
        mobile: '9610736368',
        email: 'sitenwi@gmail.com',
        },
        {
        district: 'TONK',
        tehsil: 'TODARAISINGH',
        itgkcode: '42290038',
        itgkname: 'INDIAN COMPUTERS ( DIVISION OF JAY SOFTECH )',
        mobile: '9460133732',
        email: 'jay13873@yahoo.com',
        },
        {
        district: 'TONK',
        tehsil: 'TONK',
        itgkcode: '42290072',
        itgkname: 'SMART COMPUTERS SIKSHA SANSTHAN',
        mobile: '9667492189',
        email: 'bhairu.9@gmail.com',
        },
        {
        district: 'TONK',
        tehsil: 'UNIYARA',
        itgkcode: '42290152',
        itgkname: 'STAR COMPUTER EDUCATION',
        mobile: '9784874733',
        email: 'maheshkushwah19885@gmail.com',
        },
        {
        district: 'UDAIPUR',
        tehsil: 'BADGAON',
        itgkcode: '47290372',
        itgkname: 'A TO Z COMPUTER AND COACHING CLASSES',
        mobile: '8952097964',
        email: 'riturajgayari54@gmail.com',
        },
        {
        district: 'UDAIPUR',
        tehsil: 'BHINDER',
        itgkcode: '47290049',
        itgkname: 'VISTA SANSTHAN(VCE)',
        mobile: '9887997563',
        email: 'vistasansthan.udr@gmail.com',
        },
        {
        district: 'UDAIPUR',
        tehsil: 'GEERWA',
        itgkcode: '47290060',
        itgkname: 'SHEETAL INFOSYS',
        mobile: '8233139926',
        email: 'sheetalinfosys2011@gmail.com',
        },
        {
        district: 'UDAIPUR',
        tehsil: 'GOGUNDA',
        itgkcode: '47290248',
        itgkname: 'SUN ROYAL COMPUTER EDUCATION SENTER',
        mobile: '9571455495',
        email: 'rameshkumarmeghwal998@gmail.com',
        },
        {
        district: 'UDAIPUR',
        tehsil: 'KHERWADA',
        itgkcode: '47290042',
        itgkname: 'EVEREST@CONSULTANTS',
        mobile: '9636049901',
        email: 'everest.rkcl@gmail.com',
        },
        {
        district: 'UDAIPUR',
        tehsil: 'KHERWADA',
        itgkcode: '47290042',
        itgkname: 'EVEREST@CONSULTANTS',
        mobile: '9636049901',
        email: 'everest.rkcl@gmail.com',
        },
        {
        district: 'UDAIPUR',
        tehsil: 'MAWALI',
        itgkcode: '47290035',
        itgkname: 'MANAS IT CENTRE',
        mobile: '9929499188',
        email: 'manascomputermavli@gmail.com',
        },
        {
        district: 'UDAIPUR',
        tehsil: 'NAYAGAON',
        itgkcode: '47290161',
        itgkname: 'RISHI COMPUTERS',
        mobile: '9602543678',
        email: 'hargovindjoshi1965@gmail.com',
        },
        {
        district: 'UDAIPUR',
        tehsil: 'UDAIPUR',
        itgkcode: '47290057',
        itgkname: 'DR.ANUSHKA MEMORIAL EDUCATIONAL SOCIETY',
        mobile: '9772223458',
        email: 'anushkaacademyaa3@gmail.com',
        },
        {
        district: 'UDAIPUR',
        tehsil: 'VALLABHNAGAR',
        itgkcode: '47290127',
        itgkname: 'NEW VIT BHINDER',
        mobile: '9636598328',
        email: 'menaria996@gmail.com',
        },
      
    ]
  };

  return (
    <div>
        <Header />
                <div class="container pb-5">
                    <section class="guest_opinion pt-5">
                      {/*  <h3 class="text-center pb-4 font-weight-bold title">Authorized SchoolEasy Sales Partners</h3>
                        <MDBDataTable responsiveLg
                          striped
                          bordered
                          hover
                          entriesOptions={[10,20,30,50,100]}
                          entries={10}
                          searchTop={true}
                          data={data}
                        /> */}
                    </section>
                </div>

        <Footer />

    </div>
  );
}

export default SalesPartner;
import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import banner from '../Assets/img/Asset-40.jpg'
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';
export class Slider extends Component {
    render() {
        return (
            <div id="carouselExampleTouch" className="carousel slide main_slider" data-mdb-touch="true">

<Carousel>
  <Carousel.Item id="myCarousel" data-interval="3000">
    <img
      className="d-block w-100"
      src={banner}
      alt="First slide"
    />
    <Carousel.Caption>
    <ScrollAnimation animateIn='slideInLeft' animateOut='bounceOutLeft'> 
    <h5>All in One Integrated</h5>
            <p>
              School Management System<br />
              <span> at an affordable price</span>
            </p>
    </ScrollAnimation>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={banner}
      alt="Second slide"
    />

    <Carousel.Caption>
    <h5>All in One Integrated</h5>
    <p>School Management System<br />
    <span> at an affordable price</span>
    </p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img  className="d-block w-100"  src={banner}  alt="Third slide" />

    <Carousel.Caption>
    <h5>All in One Integrated</h5>
    <p>School Management System<br />
    <span> at an affordable price</span>
    </p>
    </Carousel.Caption>

  </Carousel.Item>
</Carousel>
</div>

        )
    }
}

export default Slider

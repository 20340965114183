import React, { Component } from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import Asset16 from '../Assets/img/Asset-16.png'
import Asset17 from '../Assets/img/Asset-17.png'
import Asset18 from '../Assets/img/Asset-18.png'
import Asset19 from '../Assets/img/Asset-19.png'
import Asset20 from '../Assets/img/Asset-20.png'
import Asset21 from '../Assets/img/Improved-Efficiency.png'
import Asset22 from '../Assets/img/communicating-effectively.png'
import Asset23 from '../Assets/img/Cost-Savings.png'

export class Benifit extends Component {
     
    render() {
        const styletext = { visibility: "visible", 
            duration: "500ms"
            }
        return (
            <div>
                
                
                <div className="benifits_school" id="benifits_school">
                    <div className="container">
                        <div className="row align-items-center py-lg-5 py-4">
                        
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 managment_owner" data-mdb-toggle="animation"
                                data-mdb-animation-start="onScroll" data-mdb-animation-on-scroll="repeat"
                                data-mdb-animation="slide-in-left" >
                               <ScrollAnimation animateIn='slideInLeft' animateOut='bounceOutLeft'> 
                               <img src={Asset16} className="img-fluid" />
                               </ScrollAnimation>
                               </div>
                            
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-center text-sm-start" data-mdb-toggle="animation"
                                data-mdb-animation-start="onScroll" data-mdb-animation-on-scroll="repeat"
                                data-mdb-animation="slide-in-right">
                                    <ScrollAnimation animateIn='slideInRight' animateOut='slideInRight'>
                                <h2>
                                    Benefits to School<br />
                                    <span>Management/Owner</span>
                                </h2>
                                <p>
                                RKCLs SchoolEasy ERP solution aims to seamlessly facilitate all key 
                                stakeholders of a School entity, including principal, admin, teachers, 
                                parents & students with a fit-in-time approach and bring them together 
                                on a single unified cloud platform thereby eliminating the redundancies 
                                of legacy systems & improving operational efficiencies.

                                </p>
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="row align-items-center py-lg-5 py-0 py-sm-4">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 order-xl-1 order-lg-1 order-md-1 order-sm-1 order-2 text-center text-sm-start" data-mdb-toggle="animation"
                                data-mdb-animation-start="onScroll" data-mdb-animation-on-scroll="repeat"
                                data-mdb-animation="slide-in-left" >
                                <ScrollAnimation animateIn='slideInLeft' animateOut='bounceOutLeft'> 
                                <h2>
                                    Benefits to School <br />
                                    <span>Administration</span>
                                </h2>
                                <h4>1. Centralized Management:</h4>
                                <p>
                                
                                <ul>
                                    <li><strong>Unified Dashboard:</strong> Provides a centralized view of school operations, 
                                        including student enrolment, staff management, and financial transactions. 
                                        This consolidation simplifies oversight and decision-making. 
                                        Multiple school branches can also be integrated under single login.</li>
                                    <li><strong>Digital Identity:</strong> Dedicated Website provided with complete CMS control.</li>
                                    <li><strong>Data Integration:</strong> Integrates various school functions such as admission, fee management, 
                                        and academic progress into one system, reducing data silos and ensuring consistency. 
                                        Reduces manual errors in record-keeping, grading, and reporting, ensuring data integrity.</li>
                                    <li><strong>Data Security:</strong> Ensures complete data security by implementing robust encryption, 
                                        regular security audits, and strict role-based access controls to protect sensitive 
                                        student and administrative information</li>
                                </ul>
                                </p>
                                </ScrollAnimation>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 order-xl-2 order-lg-2 order-md-2 order-sm-2 order-1" data-mdb-toggle="animation"
                                data-mdb-animation-start="onScroll" data-mdb-animation-on-scroll="repeat"
                                data-mdb-animation="slide-in-right">
                                <ScrollAnimation animateIn='slideInRight' animateOut='slideInRight'> 
                                <img src={Asset17} className="img-fluid" />
                                </ScrollAnimation>
                            </div>
                            
                            
                        </div>
                        <div className="row align-items-center py-lg-5 py-0 py-sm-4">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 managment_owner1" data-mdb-toggle="animation"
                                data-mdb-animation-start="onScroll" data-mdb-animation-on-scroll="repeat"
                                data-mdb-animation="slide-in-left" >
                               <ScrollAnimation animateIn='slideInLeft' animateOut='bounceOutLeft'> 
                               <img src={Asset21} className="img-fluid" />
                               </ScrollAnimation>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-center text-sm-start" data-mdb-toggle="animation"
                                data-mdb-animation-start="onScroll" data-mdb-animation-on-scroll="repeat"
                                data-mdb-animation="slide-in-right">
                                    <ScrollAnimation animateIn='slideInRight' animateOut='slideInRight'> 
                                <h4>2. Improved Efficiency:</h4>
                                <p><ul>
                                    <li><strong>Automated Processes:</strong> Automates routine tasks such as fee collection, attendance tracking, and exam management, reducing administrative workload and minimizing errors.<br/>&nbsp;</li>
                                    <li><strong>Real-Time Reporting:</strong> Offers real-time access to comprehensive reports and analytics, enabling timely and informed decision-making on academic and operational matters.<br/>&nbsp;</li>
                                    <li><strong>Online Admissions:</strong> School can activate fully Online Admissions & sell admission forms online without the hassles of offline modes.</li>
                                </ul>
                                </p>
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="row align-items-center py-lg-5 py-0 py-sm-4">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 order-xl-1 order-lg-1 order-md-1 order-sm-1 order-2 text-center text-sm-start" data-mdb-toggle="animation"
                                data-mdb-animation-start="onScroll" data-mdb-animation-on-scroll="repeat"
                                data-mdb-animation="slide-in-left" >
                                <ScrollAnimation animateIn='slideInLeft' animateOut='bounceOutLeft'> 
                                <h4>3. Enhanced Communication</h4>
                                <p><ul>
                                    <li><strong>Notifications and Alerts:</strong> Facilitates instant communication with teachers, and parents, 
                                        through notifications and alerts regarding important updates, events, 
                                        and emergencies via both web and mobile app <br/>&nbsp;</li>
                                    <li><strong>Support Ticket System:</strong> Streamlines the resolution of technical issues through a structured support ticket management system.</li>
                                    
                                </ul>
                                </p>
                                </ScrollAnimation>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 managment_owner1 col-sm-6 order-xl-2 order-lg-2 order-md-2 order-sm-2 order-1" data-mdb-toggle="animation"
                                data-mdb-animation-start="onScroll" data-mdb-animation-on-scroll="repeat"
                                data-mdb-animation="slide-in-right">
                                <ScrollAnimation animateIn='slideInRight' animateOut='slideInRight'> 
                                <img src={Asset22} className="img-fluid" />
                                </ScrollAnimation>
                            </div>
                            
                            
                        </div>
                        <div className="row align-items-center py-lg-5 py-0 py-sm-4">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 managment_owner1" data-mdb-toggle="animation"
                                data-mdb-animation-start="onScroll" data-mdb-animation-on-scroll="repeat"
                                data-mdb-animation="slide-in-left" >
                               <ScrollAnimation animateIn='slideInLeft' animateOut='bounceOutLeft'> 
                               <img src={Asset23} className="img-fluid" />
                               </ScrollAnimation>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-center text-sm-start" data-mdb-toggle="animation"
                                data-mdb-animation-start="onScroll" data-mdb-animation-on-scroll="repeat"
                                data-mdb-animation="slide-in-right">
                                    <ScrollAnimation animateIn='slideInRight' animateOut='slideInRight'> 
                                <h4>4. Cost Savings:</h4>
                                <p><ul>
                                    <li><strong>Reduced Paperwork:</strong> Minimizes the need for physical documentation, cutting down on administrative costs and storage requirements.<br/>&nbsp;</li>
                                    <li><strong>Efficient Resource Allocation:</strong> Enables better management of resources and assets, leading to cost savings and optimal utilization</li>
                                    
                                </ul>
                                </p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
                

                
                <div className="benifits_table">
            <div className="container">
                <div className="row py-3">
                
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 pb-lg-0 pb-4 animation slide-in-left" data-mdb-toggle="animation" data-mdb-animation-start="onScroll" data-mdb-animation-on-scroll="repeat" data-mdb-animation="slide-in-left" style={styletext}>
                
                <div className="card pt-4 first">
                    
                    <h5 className="card-title pb-3">Benefits to Teachers</h5>
                    <img  src={Asset18} className="card-img-top" />
                    <div className="card-body uvp-list">
                        
                        <strong>1. Streamlined Workflows:</strong>
                        <ul><li>Time Table Management: Simplifies the creation and management of class schedules, including teacher assignments and room allocations.</li>
                        <li>Attendance Tracking: Automates student attendance recording and generates attendance reports, saving time and reducing manual errors.</li>
                        </ul>
                        <strong>2. Enhanced Teaching Tools:</strong>
                        <ul><li>Learning Management System (LMS): Provides tools for creating, managing, and delivering educational content and assignments, facilitating interactive and engaging learning experiences.</li>
                        <li>Assignment and Exam Management: Streamlines the process of assigning and grading homework and exams, allowing teachers to provide timely feedback to students.<br/> It also provides utilities for online Exam Marks entry and Progress Report generation.</li>
                        </ul>
                        <strong>3. Administrative Support:</strong>
                        <ul><li>Leave and Payroll Management: Automates leave requests and payroll processing, reducing administrative burdens and ensuring timely compensation and record-keeping.</li>
                        </ul>
                    </div>
                    
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 pb-lg-0 pb-4">
                    <div className="card pt-4">
                    <h5 className="card-title pb-3">Benefits to Students</h5>
                    <img src={Asset19} className="card-img-top" />
                    <div className="card-body uvp-list">
                    <strong>1. Increased Transparency:</strong>
                        <ul>
                        <li>Real-Time Updates: Provides parents with real-time access to their child’s academic performance, attendance records, and school notifications, fostering transparency and involvement.</li>
                        <li>Academic Progress Reports: Access to detailed reports on student progress, including exam results and assignment grades, helping parents stay informed about their child’s education.</li>
                        <li>Multiple Ward View: A parent can view multiple ward details under single login if he has multiple children in same school across various/same classes.</li>
                        </ul>
                        <strong>2. Enhanced Communication:</strong>
                        <ul>
                        <li>Direct Messaging: Facilitates direct communication with teachers and school administration, allowing parents to address concerns, seek clarification, and stay engaged in their child’s education.</li>
                        <li>Event Notifications: Keeps parents informed about school events, parent-teacher meetings, and other important activities through automated notifications.</li>
                        </ul>
                        <strong>3. Convenient Access:</strong>
                        <ul>
                        <li>Online/Web Access: Enables parents to manage their child’s school-related activities, including fee payments, transport arrangements, and admission processes, from the convenience of their homes.</li>
                        <li>Android App Access: Enables on the go access to all vital reports of his/her child. Easy fee payment via Mobile app.</li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 pb-0 animation slide-in-right" data-mdb-toggle="animation" data-mdb-animation-start="onScroll" data-mdb-animation-on-scroll="repeat" data-mdb-animation="slide-in-right">
                
                    <div className="card pt-4 third">
                    <h5 className="card-title pb-3">Benefits to Parents</h5>
                    <img src={Asset20} className="card-img-top" />
                    <div className="card-body uvp-list">
                    <strong>1. Enhanced Learning Experience:</strong>
                        <ul>
                        <li>Interactive LMS: Access to a range of digital tools and resources through the LMS, including multimedia content, interactive assignments, and online quizzes.</li>
                        <li>Self-Paced Learning: Opportunities for self-paced learning and access to educational materials anytime and anywhere, promoting independent study and deeper understanding.</li>
                        </ul>
                        <strong>2. Efficient Administration:</strong>
                        <ul>
                        <li>Simplified Processes: Streamlines processes such as fee payment, assignment submission, and exam registration, making school-related tasks more manageable for students.</li>
                        <li>Attendance Tracking: Provides students with visibility into their attendance records and performance, encouraging responsibility and accountability.</li>
                        </ul>
                        <strong>3. Improved Feedback:</strong>
                        <ul>
                        <li>Instant Feedback: Receives immediate feedback on assignments and exams, helping students understand their strengths and areas for improvement.</li>
                        <li>Progress Tracking: Allows students to track their academic progress and set goals based on performance data and feedback.</li>
                        </ul>
                        <strong>4. Enhanced Support::</strong>
                        <ul>
                        <li>Query Management: Enables students to submit and track queries or doubts, ensuring timely resolution and support for academic challenges.</li>
                        <li>Extra-Curricular Activities: Facilitates participation in extracurricular activities and events, promoting holistic development and engagement.</li>
                        </ul>
                    </div>
                    </div>
                
                </div>
                </div>
            </div>
            </div>

            </div>
        )
    }
}

export default Benifit

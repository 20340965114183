import React, { PureComponent } from "react"

import ScrollAnimation from 'react-animate-on-scroll';
import Header from './Header';
import Footer from './Footer';

import client_img from '../Assets/img/about.jpg'
import school_logo from '../Assets/img/logo/MSMS-Vidhyalaya.jpg'
import client_img2 from '../Assets/img/Photos/2.png'
import client_img3 from '../Assets/img/Photos/3.png'
import client_img4 from '../Assets/img/Photos/4.png'
import client_img5 from '../Assets/img/Photos/5.png'
import client_img6 from '../Assets/img/Photos/6.png'
import client_img7 from '../Assets/img/Photos/7.png'
import client_img8 from '../Assets/img/Photos/8.png'

export class App extends PureComponent {
  

  render() {
    
    
    return (
      <div>
      	<Header/>
        
          <div id="intro-example" class="p-5 text-center bg-image" style={{backgroundImage: "url(" + client_img + ")"}}>
      <div class="mask" >
        <div class="d-flex justify-content-center align-items-center h-100">
          <div class="text-white">
            <h1>Testimonials For SchoolEasy</h1>
          </div>
        </div>
      </div>
    </div>
        
    <div class="container pb-5">
    <section class="text-center guest_opinion">
    <h3 class="text-center pb-4 font-weight-bold title">Our Client Opinion</h3>
    <div class="row pt-4">
      <div class="col-md-12 col-lg-4 mb-4" data-mdb-toggle="animation"
      data-mdb-animation-start="onScroll" data-mdb-animation-on-scroll="repeat"
      data-mdb-animation="slide-in-left">
          <ScrollAnimation animateIn='slideInLeft' animateOut='slideOutLeft'>
        <img class="rounded-circle shadow-1-strong mb-4"
          src={school_logo} alt="avatar"  />
        <h5 class="mb-2">Sawai Man Singh</h5>
        <p class="font-weight-bold text-primary">Maharaja Sawai Man Singh Vidyalaya</p>
        <p>
          <i class="fas fa-quote-left pe-2"></i>
        School easy is a user friendly and affordable ERP solution to manage administrative tasks in an effective manner. Staff is very supportive and helpful" Maharaja Sawai Man Singh Vidyalaya, Jaipur निःसन्देह SchoolEasy एक बहुत बढ़िया. 
         <i class="fas fa-quote-right pe-2"></i></p>
         </ScrollAnimation>
      </div>
      <div class="col-md-6 col-lg-4 mb-4">
        <img class="rounded-circle shadow-1-strong mb-4"
          src={client_img2} alt="avatar" />
        <h5 class="mb-2">Vinay Soni, Chairman</h5>
        <p class="font-weight-bold text-primary">St. Thomas Sr. Sec. School, Fatehnagar- Udaipur</p>
        <p>
          <i class="fas fa-quote-left pe-2"></i>
        It is so easy to use and convenient application which makes school management so easy.After using this application my whole staff, students,exams, online teaching and other proceedings have been amazingly handled .I genuinely recommend this application to all the school to get better results by its innovative features. 
         <i class="fas fa-quote-right pe-2"></i>
        </p>
      </div>
      <div class="col-md-6 col-lg-4 mb-4 testimonial" data-mdb-toggle="animation"
      data-mdb-animation-start="onScroll" data-mdb-animation-on-scroll="repeat"
      data-mdb-animation="slide-in-right">
          <ScrollAnimation animateIn='slideInRight' animateOut='slideOutRight'>
        <img class="rounded-circle shadow-1-strong mb-4"
          src={client_img3} alt="avatar"  />
        <h5 class="mb-2">Sangi Dan Paliwal - Director</h5>
        <p class="font-weight-bold text-primary">Soft-Tech School-Jodhpur</p>
        <p>
          <i class="fas fa-quote-left pe-2"></i>
        SchoolEasy is a complete fully integrated online cloud-based ERP package and very user-friendly package which fulfil all needs of institute. It is very dynamic and manageable. It has helped us considerably to keep track of each and every activity/data of our school. 
         <i class="fas fa-quote-right pe-2"></i></p>
         </ScrollAnimation>
      </div>
      <div class="col-md-12 col-lg-4 mb-4" data-mdb-toggle="animation"
      data-mdb-animation-start="onScroll" data-mdb-animation-on-scroll="repeat"
      data-mdb-animation="slide-in-left">
          <ScrollAnimation animateIn='slideInLeft' animateOut='slideOutLeft'>
        <img class="rounded-circle shadow-1-strong mb-4"
          src={client_img4} alt="avatar"  />
        <h5 class="mb-2">Sumit Goyal</h5>
        <p class="font-weight-bold text-primary">Principal Orange County School, Rajsamand</p>
        <p>
         <i class="fas fa-quote-left pe-2"></i>
        Exam and assignment management system, Lesson planning, Library and asset management, website, students and staff data, different type of reports, TC generation, transport, fee and leave management and many such utilities are a part of the ERP which has really made the management of school very convenient. 
        <i class="fas fa-quote-right pe-2"></i></p>
        </ScrollAnimation>
      </div>
      <div class="col-md-6 col-lg-4 mb-4">
        <img class="rounded-circle shadow-1-strong mb-4"
          src={client_img5} alt="avatar"  />
        <h5 class="mb-2">मथुरेश गुर्जर, डायरेक्टर</h5>
        <p class="font-weight-bold text-primary">जेडी पब्लिक स्कूल, सिकंदरा, दौसा</p>
        <p>
          <i class="fas fa-quote-left pe-2"></i>
        निःसन्देह SchoolEasy एक बहुत बढ़िया सॉफ्टवेयर है, इसके माध्यम से मेरी स्कूल का प्रबंधन बेहतरीन तरीके से कर पा रहा हूं इसके माध्यम स्कूल की सभी गतिविधियों का संचालन बहुत आसान हो गया है। 
         <i class="fas fa-quote-right pe-2"></i></p>
      </div>
      <div class="col-md-6 col-lg-4 mb-4" data-mdb-toggle="animation"
      data-mdb-animation-start="onScroll" data-mdb-animation-on-scroll="repeat"
      data-mdb-animation="slide-in-right">
          <ScrollAnimation animateIn='slideInRight' animateOut='slideOutRight'>
        <img class="rounded-circle shadow-1-strong mb-4"
          src={client_img6} alt="avatar"  />
        <h5 class="mb-2">Sanjana Narula</h5>
        <p class="font-weight-bold text-primary">Perfect Academy, Hanumangarh</p>
        <p>
          <i class="fas fa-quote-left pe-2"></i>
        SchoolEasy, This application works just liket its name. Digital interface among management, teachers, parents, students, is very much helpful to manage all academic operations on mobile. Low cost and maximum features. 
         <i class="fas fa-quote-right pe-2"></i></p>
         </ScrollAnimation>
      </div>
      <div class="col-md-6 col-lg-4 mb-4" data-mdb-toggle="animation"
      data-mdb-animation-start="onScroll" data-mdb-animation-on-scroll="repeat"
      data-mdb-animation="slide-in-right">
          <ScrollAnimation animateIn='slideInLeft' animateOut='slideOutLeft'>
        <img class="rounded-circle shadow-1-strong mb-4"
          src={client_img7} alt="avatar"  />
        <h5 class="mb-2">ओम सिंह राजपुरोहित, प्रधानाचार्य</h5>
        <p class="font-weight-bold text-primary">वर्धमान जैन सी.सै. स्कूल, ओसियां</p>
        <p>
          <i class="fas fa-quote-left pe-2"></i>
        SchoolEasy सरल एवं सम्पूर्ण स्कूल प्रबंधन का उत्कृष्ट ईआरपी सॉफ्टवेर है, जो स्कूल के सभी छात्र-छात्रों, शिक्षकों, अभिभावकों एवं स्कूल प्रबंधन से जुड़े हुए सभी के लिए उपयोगी है। इसमें हमें हमारी वैबसाइट, ऑनलाइन पेमेंट सुविधा, माइक्रोसॉफ्ट टीम्स के माध्यम से ऑनलाइन क्लास, पाठ्यक्रम सामग्री, रिपोर्ट्स एवं प्रबंधन, तथा दैनिक शिक्षण कार्यो का बहुत ही सुव्यवस्थित तरीके से सम्पादन किया जाता है। 
         <i class="fas fa-quote-right pe-2"></i></p>
         </ScrollAnimation>
      </div>
      <div class="col-md-6 col-lg-4 mb-4" data-mdb-toggle="animation"
      data-mdb-animation-start="onScroll" data-mdb-animation-on-scroll="repeat"
      data-mdb-animation="slide-in-right">
          
        <img class="rounded-circle shadow-1-strong mb-4"
          src={client_img8} alt="avatar"  />
        <h5 class="mb-2">राजेश कुमार मलिक</h5>
        <p class="font-weight-bold text-primary">पैराडाइज पब्लिक स्कूल, प्रतापगढ़</p>
        <p>
          <i class="fas fa-quote-left pe-2"></i>
        SchoolEasy सॉफ्टवेयर 21वी सदी की स्कूल प्रबंधन की सभी समस्याओं का एक सर्वश्रेष्ठ उपाय है। इसके उपयोग के बाद से मेरे स्टाफ, विद्यार्थी, परीक्षा, ऑनलाइन शिक्षण, ओर एकाउंटिंग जैसे सभी कार्य बहुत ही आसानी ओर सरलतापूर्वक होने लगे हैं। यह स्कूल प्रबंधन का श्रेष्ठ विकल्प है। 
         <i class="fas fa-quote-right pe-2"></i></p>
      </div>

    </div>
  </section>
</div> 
        
        <Footer/>
        
      </div>
    );
  }
}

export default App
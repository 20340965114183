import React, { Component } from 'react';
//import ScrollAnimation from 'react-animate-on-scroll';
//import Asset21 from '../Assets/img/Asset-21.png';
//import Asset16 from '../Assets/img/Asset-16.png';
//import Asset17 from '../Assets/img/Asset-17.png';
//import SchoolEasy_module from '../Assets/img/SchoolEasy-module.png';
//import SchoolEasy_new_module from '../Assets/img/SchoolEasy-new-module.png';

export class SchoolEasy extends Component {
    render() {
        return (
            <div>
                <div className="benifits_school" id="feature">
                    <div className="container">
                        <div className="row align-items-center py-lg-5 py-4">
                        {/* <img src={SchoolEasy_new_module} className="img-fluid" /> */}
                        <div className='table-responsive pricing-table pt-3'>
                        <table className="table table-bordered table-strip">
                        <tr>
                            <th style={{fontWeight:600,fontSize:'22px',textAlign:'left',padding:'8px',width:'5%'}}>S.No.</th>
                            <th style={{fontWeight:600,fontSize:'22px',textAlign:'left',padding:'8px',width:'35%'}}>Features</th>
                            <th style={{fontWeight:600,fontSize:'22px',width:'15%'}}>Website Only</th>
                            <th style={{fontWeight:600,fontSize:'22px',width:'15%'}}>Basic</th>
                            <th style={{fontWeight:600,fontSize:'22px',width:'15%'}}>Pro</th>
                            <th style={{fontWeight:600,fontSize:'22px',width:'15%'}}>Ultra</th>
                        </tr>
                        
                        <tr>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'left'}}>Target Client Type</td>
                            <td style={{textAlign:'left'}}>School/ College/ ITI/ Coaching/RKCL ITGK</td>
                            <td style={{textAlign:'center'}}>School only</td>
                            <td style={{textAlign:'center'}}>School only</td>
                            <td style={{textAlign:'center'}}>School only</td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'left'}}>ERP Portal Login</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'left'}}>Website Creation/ Hosting + CMS Login</td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'center'}}>4</td>
                            <td style={{textAlign:'left'}}>Website Domain (non-https) - if opted</td>
                            <td style={{fontWeight:600,fontSize:'14px'}}>Charged Extra</td>
                            <td style={{fontWeight:600,fontSize:'14px'}}>Charged Extra</td>
                            <td style={{fontWeight:600,fontSize:'14px'}}>Charged Extra</td>
                            <td style={{fontWeight:600,fontSize:'14px'}}>Charged Extra</td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>5</td>
                            <td style={{textAlign:'left'}}>HTTPS Website Domain (SSL) - if opted</td>
                            <td style={{fontWeight:600,fontSize:'14px'}}>Charged Extra</td>
                            <td style={{fontWeight:600,fontSize:'14px'}}>Charged Extra</td>
                            <td style={{fontWeight:600,fontSize:'14px'}}>Charged Extra</td>
                            <td style={{fontWeight:600,fontSize:'14px'}}>Charged Extra</td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>6</td>
                            <td style={{textAlign:'left'}}>Website Customized Themes</td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>7</td>
                            <td style={{textAlign:'left'}}>School Management (Data Configuration)</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>8</td>
                            <td style={{textAlign:'left'}}>Teacher Management</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>9</td>
                            <td style={{textAlign:'left'}}>Admission Management (includes Old Data Import, Promote) & Reports</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>10</td>
                            <td style={{textAlign:'left'}}>Fee Management (offline) & Reports</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>11</td>
                            <td style={{textAlign:'left'}}>Online Fees Payment Gateway Integration</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>12</td>
                            <td style={{textAlign:'left'}}>Android App (School/Parents)</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                         <tr><td style={{textAlign:'center'}}>13</td>
                            <td style={{textAlign:'left'}}>Notifications/Communications (via App)</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>14</td>
                            <td style={{textAlign:'left'}}>Transport Route Management (non GPS)</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            
                        </tr>
                        <tr><td style={{textAlign:'center'}}>15</td>
                            <td style={{textAlign:'left'}}>Accounts Management/ Bookkeeping Module (Payment/Receipts/Journals/Contra)</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>16</td>
                            <td style={{textAlign:'left'}}>Certificates (TC, Character Certificate)</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>17</td>
                            <td style={{textAlign:'left'}}>Online Attendance Management</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>18</td>
                            <td style={{textAlign:'left'}}>Biometric Attendance for School Staff (Hardware not included)</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>19</td>
                            <td style={{textAlign:'left'}}>Leave Management (HRM) for Staff</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            
                        </tr>
                        <tr><td style={{textAlign:'center'}}>20</td>
                            <td style={{textAlign:'left'}}>Payroll (Salary Slip/Deductions/PF)</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>21</td>
                            <td style={{textAlign:'left'}}>LMS (Learning Management System) - eContent Upload</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>22</td>
                            <td style={{textAlign:'left'}}>Assignment Management - Homework</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            
                        </tr>
                        <tr><td style={{textAlign:'center'}}>23</td>
                            <td style={{textAlign:'left'}}>Query & Doubt Management</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>24</td>
                            <td style={{textAlign:'left'}}>Complaint Management</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>25</td>
                            <td style={{textAlign:'left'}}>Exam (Online mode)</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>26</td>
                            <td style={{textAlign:'left'}}>Exam Progress Report + Customized Templates</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        
                         <tr><td style={{textAlign:'center'}}>27</td>
                            <td style={{textAlign:'left'}}>Gate Pass (Visitor Management) Module</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>28</td>
                            <td style={{textAlign:'left'}}>Library Management</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            
                        </tr>
                        <tr><td style={{textAlign:'center'}}>29</td>
                            <td style={{textAlign:'left'}}>Extra-Curricular Activity Management</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>30</td>
                            <td style={{textAlign:'left'}}>Assets Management (Inventory)</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>31</td>
                            <td style={{textAlign:'left'}}>Tech Support Mechanism</td>
                            <td>Email/Phone/Remote</td>
                            <td>Email</td>
                            <td>Email/Phone/Remote</td>
                            <td>Email/Phone/Remote</td>
                        </tr>
                        <tr><td style={{textAlign:'center'}}>32</td>
                            <td style={{textAlign:'left'}}>Tech Issue Tracking (Ticket System)</td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr ><td style={{textAlign:'center'}}>33</td>
                            <td style={{textAlign:'left'}}>Training for Client Staff (Online Mode)</td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                            <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        </tr>
                        <tr ><td style={{textAlign:'center'}}>34</td>
                            <td style={{textAlign:'left'}}>Training for Client Staff (On Site)</td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            <td>2 times/year</td>
                            <td>4 times/year</td>
                        </tr>
                    <tr><td style={{textAlign:'left', background:'#fff', color:'rgba(212, 5, 5, 0.786)'}} colSpan={6}>Note 1 - For exact plan prices - kindly contact - <a href='mailto:bishalc@rkcl.in'>bishalc@rkcl.in</a> we only charge flat annual rates irrepsective of student count. Hence no hassles of per student pricing.</td></tr>
                    <tr><td style={{textAlign:'left', background:'#fff', color:'rgba(212, 5, 5, 0.786)'}} colSpan={6}>Note 2 - There is No Refund Policy.</td></tr>
                    <tr><td style={{textAlign:'left', background:'#fff', color:'rgba(212, 5, 5, 0.786)'}} colSpan={6}>Note 3 - All payments to be made in advance via UPI ID/UPI QR/NEFT mode to RKCL Bank Account in advance. We do not accept Cash/DD/Cheque. Payment page link - <a href='https://schooleasyerp.com/payment'>https://schooleasyerp.com/payment</a></td></tr>
                    <tr><td style={{textAlign:'left', background:'#fff', color:'rgba(212, 5, 5, 0.786)'}} colSpan={6}>Note 4 - Once a Plan is purchased by School, we dont allow change of Plan for next 365 days. Plan upgrade is only possible after 1 year.</td></tr>
                    {/*<tr><td style={{textAlign:'left'}} colSpan={5}>
                        <p><b><u>SchoolEasy payment to be done on following RKCL bank account</u></b> -<br/>
                        <b>Account Holder Name -</b> Rajasthan Knowledge Corporation Ltd.<br/>
                        <b>Account Number -</b> 911020042604401<br/>
                        <b>IFSC Code -</b> UTIB0000031<br/>
                        <b>Bank Name -</b> Axis Bank, Tilak Nagar, Jaipur</p>
                    </td></tr>*/}
                    </table>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SchoolEasy

import React, { Component } from "react"
import "../Assets/css/style.css";
import "../Assets/css/responsive.css";
import Header from './Header';
import Footer from './Footer';
import handshake from '../Assets/img/handshake-icon.png'

export class PrivacyPolicy extends Component {


    render() {

        return (
            <div>
                <Header />
                <div class="container pb-5">
                    <section class="guest_opinion pt-5">
                        <h3 class="text-center pb-4 font-weight-bold title">Join as SchoolEasy Dealer (Reseller Partner)</h3>
                        <div class="pt-3">
                        <h5>RKCL Brand Introduction</h5>
                        
                        <p>Rajasthan Knowledge Corporation Limited (RKCL) is a public limited company promoted by Government of Rajasthan and appointed as the nodal organization for IT/ITeS related training and capacity building under the aegis of Government of Rajasthan. </p>
                        
                        <p>Since its inception, RKCL has set many milestones in the fields of Digital Literacy, e-governance and e-empowerment projects. RKCL has trained more than 70 Lakh Learners since its inception and these learners have become smart users of IT. RKCL has been instrumental in spreading digital literacy in Rajasthan through Government recognized RS-CIT (Rajasthan State Certificate in IT) course leveraging its ever-expanding network of 6500+ ITGKs across all the districts & tehsils of Rajasthan <strong>thereby playing a pivotal role to inspire youth on IT, bridging the digital divide, making citizens smart users of IT and gradually spreading digital revolution in Rajasthan. </strong></p>

                        <p>RKCL is spearheading skill training, development and capacity building revolution in Rajasthan through its training programs like RS-CIT, RS-CFA (Financial Accounting), Spoken English & Personality Development therefore making youth of Rajasthan confident and job ready. In addition, looking at the requirements of technology-based professionals, RKCL has also forayed into technology-based Job oriented training programs like Digital Marketing, Advanced Excel, Web Designing, Web Development, Graphic Designing & Python Programming.
                        </p>
                        <p>In addition to training programs and courses, RKCL has developed and is successfully running a cloud-based School Management Solution “SchoolEasy” with a vision to digitize all schools of Rajasthan in every nook and corner of the state. SchoolEasy software aims to seamlessly facilitate all key stakeholders of a School entity, including students, teachers, administrators, and parents, with a fit-in-time approach and bring them together on a single unified cloud platform thereby eliminating the redundancies of legacy systems & improving operational efficiencies.
                        </p></div>
                        <div class="pt-3">
                        <h5>SchoolEasy Dealership</h5>
                        <p>SchoolEasy offer the best and most comprehensive school management system covering all stakeholders (i.e. School Administration, Teachers, Parents, and Students). Lots of schools have adopted our solution in various ways. Several schools are using it as a complete school management ERP covering all the operational aspects of a School.</p>
                        <p>To reach out to new schools & geography, we at RKCL are hence inviting existing school suppliers (book/hardware/software) with good school lead network to become esteemed SchoolEasy partners of RKCL.</p>
                        <p className="handshake"><img className="img-fluid1" src={handshake} /></p>
                        <p>The collaboration journey is always inviting and engaging. Together, we can achieve individual and conjunctive goals with better efficiency. We provide an opportunity to work with the most accredited brand RKCL to eliminate the flaws of conventional chalk and talk learning and provide an advanced system of learning to Schools. Facilitate your stakeholders, including students, teachers, administrators, and parents, with a fit-in-time approach and bring them together on a single unified cloud platform. Choose the best partner program that suits your business. Join hands with RKCL to start the digitalization journey with top-notch School ERP solution named SchoolEasy.</p>
                        <p>We strongly believe that we can go fast, if we're going alone. However, to go far, we need to go together. There are schools, colleges and private institutes everywhere around India. We can't reach everywhere. In order to serve educational organisations around pan India, we have designed a special partnership program for companies or small teams to work closely with our team and grow together. Basically, partners help us reach potential customers and present the product to grow the business.</p>

                        </div>
                        <div class="pt-3">
                        <h5>SchoolEasy Dealership Program Benefits:</h5>
                        <ul>
                          <ol>1.	Unmatched marketing, training, pre-sales, and post-sales support to partners.</ol>
                          <ol>2.	Joint marketing campaigns and synergies.</ol>
                          <ol>3.	Constant upgradation of product feature and functions.</ol>
                          <ol>4.	Access to each other's customer base.</ol>
                          <ol>5.	Provides partners with enormous reach in the education market.</ol>
                          <ol>6.	Motivated Team to support you at every step throughout your way.</ol>
                          <ol>7.	Customization Support.</ol>
                        </ul>
                        </div>
                        <div class="pt-3">
                        <h5>RKCL offers an unique model of Dealership (software resellership) as per applicant company profile - </h5>
                        <ul>
                            <ol>1.  Software Dealer - In this model, a security one time deposit of Rs 5,000 (online advance payment) is required to enrol as a dealer.</ol>
                            <ol>2.	We offer attractive commissions in the range of 35% to 40% per sale based plan subscriptions sold or renewed.</ol>
                        </ul>
                            <p>In above model Exclusive Territory Rights are also offered if dealer profile is deemed suitable. You can associate with us as a business entity for selling SchoolEasy product to schools and educational institutions for a pre-decided Territory, by leveraging your existing business connections or connections with Schools. RKCL team will help you meet the needs of the end-customers by leveraging our products & providing operational support at every step to ensure mutually beneficial relationship. Get complete training & assistance from our sales team to boost the chances of closing the sale successfully.</p>
                        </div>
                        <div class="pt-3">
                        <h5>Following are the key highlights of this partnership: </h5>
                        <ul>
                            <li>Dedicated 24×7 support</li>
                            <li>Free Training</li>
                            <li>State Level Marketing (RKCL website & Newspaper Ads) & Promo material support</li>
                            <li>Dedicated Demo Logins</li>
                            <li>Support Ticket System for tracking technical issues</li>
                            <li>Lead generation support</li>
                            <li>Free Access to RKCL leads</li>
                            <li>Special deals on Bulk sales</li>
                            <li>Recurring Income via attractive commissions on each sale</li>
                            <li>Partner Agreements</li>
                            <li>Dedicated Sales Manager</li>
                            <li>On time prompt Billing Support</li>
                        </ul>
                    <p>This model is ideal for existing school suppliers like hardware/software/book vendors who have a large base of existing schools as their customers.</p>
                    <p>Application Process for Dealership - 
                        <ol>1.  Kindly pay Dealership fees using RKCL details at URL - <a href="https://schooleasyerp.com/payment">https://schooleasyerp.com/payment</a></ol>
                        <ol>2.  Kindly drop an email along with full contact details, payment success details and company profile to our representative - <a href="mailto:bishalc@rkcl.in">bishalc@rkcl.in</a> ; <a href="tel:9649900913">9649900913</a></ol>
                        <ol>3.  Our team will connect with you for Agreement sign off & Training</ol>
                    </p>
                    </div>
                    </section>
                </div>
                <Footer />

            </div>
        );
    }
}

export default PrivacyPolicy
import React, { Component } from 'react';

//import { BrowserRouter as Router, Routes, Route, link} from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route as Route6 } from 'react-router';

import Home from './components';
//import About from './components/about';
import About from './components/AboutSchoolEasy';
import Testimonial from './components/Testimonial';
import Contact from './components/Contact';
import PrivacyPolicy from './components/PrivacyPolicy';
import JoinAsDealer from './components/JoinAsDealer';
import AuthorizedDealers from './components/AuthorizedDealers';
import SalesPartner from './components/SalesPartner';
import Client_list from './components/Client_list';
import Payment from './components/PaymentStatic';
//class App extends Component {
 // render(){
    function App() {
    return (
    <Router>
      <Routes>
        <Route6 exact path='/' element={<Home/>} />
        <Route6 path='/about' element={<About/>} />
        <Route6 path='/contact' element={<Contact/>} />
        <Route6 path='/testimonial' element={<Testimonial/>}/>
        <Route6 path='/privacypolicy' element={<PrivacyPolicy/>} />
        <Route6 path='/join-as-dealer' element={<JoinAsDealer/>} />
        <Route6 path='/authorized-dealers' element={<AuthorizedDealers/>} />
        <Route6 path='/salespartner' element={<SalesPartner/>} />
        <Route6 path='/clientlist' element={<Client_list/>} />
        <Route6 path='/payment' element={<Payment/>} />
      </Routes>
    </Router>
    );
 // }
  }

export default App;

import React, { Component } from "react";
import { render } from "react-dom";
import ScrollAnimation from 'react-animate-on-scroll';
import Odometer from "react-odometerjs";
import 'odometer/themes/odometer-theme-default.css';
import Asset1 from '../Assets/img/Asset-1.png'
import Asset2 from '../Assets/img/student-icon.png'
import Asset3 from '../Assets/img/Asset-3.png'
import Asset4 from '../Assets/img/Asset-4.png'
import Asset5 from '../Assets/img/Asset-5.png'
import Asset6 from '../Assets/img/Asset-6.png'
import Asset7 from '../Assets/img/Asset-7.png'
import Asset8 from '../Assets/img/Asset-8.png'
import Asset9 from '../Assets/img/Asset-9.png'
import Asset10 from '../Assets/img/Asset-10.png'
import Asset11 from '../Assets/img/Asset-11.png'
import Asset12 from '../Assets/img/Asset-12.png'
import Asset15 from '../Assets/img/Asset-15.png'
import IconCity from '../Assets/img/parent-icon.png'
import axios from "axios";

export class Middle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      odometerValue: 0,
      odometerValue1: 0,
      odometerValue2: 0,
      odometerValue3: 0
    };
  }

  componentDidMount() {
    
    this.setState({ odometerValue: 70 });
    this.setState({ odometerValue1: "30,000" });
    this.setState({ odometerValue2: 1000 });
    this.setState({ odometerValue3: "30,000" });
  }
  
  render() {
    const { odometerValue,odometerValue1,odometerValue2,odometerValue3 } = this.state;
    return (
      <div>
        <div className="middlelayer">
          <div className="container">
            <div className="row justify-content-between py-3">
              <div className="col-xl-2 col-lg-2 col-md-3 col-12 d-flex align-items-center justify-content-center pb-4 pb-md-0">
                <img src={Asset1} className="img-fluid" />
                <span className="font_set ms-3">
                <Odometer format="d" duration={500} value={odometerValue} />+  
                  <br />
                  <abbr>School</abbr>
                </span>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-3 col-12 d-flex align-items-center justify-content-center pb-4 pb-md-0">
                <img src={Asset2} className="img-fluid" />
                <span className="font_set ms-3">
                <Odometer format="d" duration={500} value={odometerValue1} />+  
                  <br/>
                  <abbr>Students</abbr>
                </span>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-3 col-12 d-flex align-items-center justify-content-center pb-4 pb-md-0">
                <img src={Asset3} className="img-fluid" />
                <span className="font_set ms-3">
                <Odometer format="d" duration={500} value={odometerValue2} />+  
                <br/>
                  <abbr>Teachers</abbr>
                </span>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-3 col-12 d-flex align-items-center justify-content-center pb-4 pb-md-0">
                <img src={IconCity} className="img-fluid" />
                <span className="font_set ms-3">
                <Odometer format="d" duration={500} value={odometerValue3} />+  
                <abbr> Parents</abbr>
                <br/>
                </span>
              </div>
              {/*<div className="col-xl-2 col-lg-2 col-md-3 col-12 d-flex align-items-center justify-content-center pb-4 pb-md-0">
                <img src={Asset4} className="img-fluid assets_4" />
                <span className="font_set ms-3">
                <i className="fas fa-rupee-sign"></i> 10,000/
                  <br />
                  <abbr>
                    1<sup>st</sup> Year Activation
                  </abbr>
                </span>
            </div>*/}
              <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12  justify-content-center pt-lg-0 pt-4 d-flex align-items-center">
                <a   href="https://school.myrkcl.com/frmmanageownerouter" ><button className="btn btn-primary btn-lg">Buy Now</button> </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container editable_text" id="introduction">
          <div className="row py-4" data-mdb-toggle="animation"
            data-mdb-animation-start="onScroll" data-mdb-animation-on-scroll="repeat"
            data-mdb-animation="slide-in-down">
          
            <p>
            SchoolEasy® is a full online School Management System that enables a school to use and operate many of integrated interrelated modules and manage the overall administration of a school efficiently. 
Due to its ever growing and competitive nature, the education sector has always been in need of a quality solution to manage and serve the school resources efficiently.  {/*<a href="/introduction" target="_blank">Read More </a>*/}
            </p>
            
          </div>
        </div>

        <div className="list_items_group">
          <div className="container">
            <div className="row justify-content-between py-lg-3 py-0">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 order-lg-1 order-sm-2 order-1 text-sm-end text-center align-items-center first_column py-lg-0 py-sm-5 py-0" >
                <div className="first">
                  <p>Management Dashboard<img src={Asset5} height="35" /></p>
                </div>
                <div className="second">
                  <p>Fees Accounting & Collection<img src={Asset8} height="35" /></p>
                </div>
                <div className="d-flex align-items-center justify-content-sm-end justify-content-center third">
                  <p>
                    Report Card Generation <br />
                    & Result Analysis
                  </p>
                  <p><img src={Asset7} height="35" /></p>
                </div>
                <div className="fourth">
                  <p>Attendance Entry<img src={Asset6} height="35" /></p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 order-lg-2 order-sm-1 order-2 text-center align-items-center second_column ">
                <img src={Asset15} className="img-fluid" />
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 order-lg-3 order-sm-3 order-3 text-sm-start text-sm-start text-center align-items-center third_column py-lg-0 py-sm-5 py-5">
                <div className="first">
                  <p><img src={Asset11} height="35" />Cloud Based Deployment</p>
                </div>
                <div className="second">
                  <p><img src={Asset9} height="35" />Biometric Device Compatibility</p>
                </div>
                <div className="third">
                  <p><img src={Asset12} height="35" />Parent Portal</p>
                </div>
                <div className="fourth">
                  <p><img src={Asset10} height="35" />E-mail & SMS</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default Middle;

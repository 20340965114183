import React, { Component } from 'react'
import { Link } from 'react-router-dom';

//import Nav from "react-bootstrap/Nav";

import '../Assets/css/mdb.min.css';
import "../Assets/css/style.css";
import "../Assets/css/animation.css";
import "../Assets/css/responsive.css";
import "../Assets/js/custom.js";

import logo from '../Assets/img/logo.png'
import brochure from '../Assets/pdf/SchoolEasy-brochure.pdf'
import poster from '../Assets/pdf/SchoolEasy-poster.pdf'
import Navbar from "react-bootstrap/Navbar";

import { Nav , NavDropdown } from "react-bootstrap";

export class Header extends Component {
  render() {
    return (
      <div id="topbar">

        <div className="topbar">
          <div className="container-fluid ">
            
            <div className="row justify-content-end py-md-1 py-2">

              <div className="col-xl-4 col-md-4 col-lg-5 col-md-6 col-12">
                <ul className="list-inline mb-0">
                  <li className="list-inline-item"><a href="https://www.rkcl.in/frmdemoforschooleasy.php" target="_blank" className="text-white">Free Demo Booking</a></li>
                  <li className="list-inline-item">
                    <a href="https://school.myrkcl.com/frmmanageownerouter"><i className="far fa-user-circle"></i> Register </a>/ <a href="https://school.myrkcl.com/index.php">Login</a>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div><div className="colorful"></div>
        </div>
        <div id="navbar">
 <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
  <div className="container-fluid">
  <Navbar.Brand as={Link} to="/"><img src={logo} height="50" alt="Easy School Logo" loading="lazy" /></Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
  
    <Nav >
      <Nav.Link as={Link} to="/about">About</Nav.Link>
      <Nav.Link href="/#benifits_school">Benefits</Nav.Link>
      <Nav.Link href="/#feature">Features</Nav.Link>
      <Nav.Link href="/#price">Pricing&nbsp;Details</Nav.Link>
      <NavDropdown title="Buy SchoolEasy" id="collasible-nav-dropdown">
        <NavDropdown.Item href="https://school.myrkcl.com/frmManageOwnerOuter.php" target="_blank">Register New School Owner</NavDropdown.Item>
        <NavDropdown.Item href="https://school.myrkcl.com/" target="_blank">Register School</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/payment" >Payment for SchoolEasy</NavDropdown.Item>
      </NavDropdown>
      {/* <NavDropdown title="Resources" id="collasible-nav-dropdown">
        <NavDropdown.Item href={brochure} without rel="noopener noreferrer" target="_blank">Brochure</NavDropdown.Item>
        <NavDropdown.Item href={poster} without rel="noopener noreferrer" target="_blank">Poster</NavDropdown.Item>
        <NavDropdown.Item href="https://www.youtube.com/watch?v=l965fNCdUCU" target="_blank">Video Ad</NavDropdown.Item>
        <NavDropdown.Item href="https://www.youtube.com/watch?v=cE2hZzFRQqs&t=17s" target="_blank">Webinar</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="#">Press Coverage</NavDropdown.Item>
        <NavDropdown.Item href="https://play.google.com/store/apps/details?id=com.rkcl.schoolmanageent&hl
" target="_blank">Android App</NavDropdown.Item>
      </NavDropdown> */}

      <NavDropdown title="Contact Us" id="collasible-nav-dropdown">
        <NavDropdown.Item as={Link} to="/authorized-dealers">Find a nearest authorized Dealer</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/contact"> Customer Care (Tech Support) </NavDropdown.Item>
        
      </NavDropdown>
      <NavDropdown title="Software Reselling Dealer Program" id="collasible-nav-dropdown">
        <NavDropdown.Item as={Link} to="/join-as-dealer">Join as Dealer</NavDropdown.Item>
        {/*<NavDropdown.Item href="/salespartner">Rajasthan</NavDropdown.Item>*/}
        <NavDropdown.Item as={Link} to="/authorized-dealers">Authorized Dealers</NavDropdown.Item>
        
      </NavDropdown>
      <NavDropdown title="Clients" id="collasible-nav-dropdown">
        <NavDropdown.Item as={Link} to="/clientlist">Clients List</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/testimonial">Client Testimonials</NavDropdown.Item>
      </NavDropdown>
    </Nav>
    
  </Navbar.Collapse>
  </div>
</Navbar>
</div> 
      </div>
    )
  }
}

export default Header

import React, { PureComponent } from "react";

import Header from './Header';
import Footer from './Footer';
import axios from "axios";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';

import client_img from '../Assets/img/about.jpg';
import contact_img from '../Assets/img/graphics.png';

export class App extends PureComponent {
  constructor(props) {    
    super(props);    
    this.state = {    
        name: "",
        mobile:"",
        email:"",
        message:"",
        subject:"SchoolEasy contact us form",
        user_captcha_input:"",
    formErrors: {}    
    };    

    this.initialState = this.state;    
}  
allLetter(inputtxt)
{
var letters = /^[A-Za-z]+$/;
if(inputtxt.value.match(letters))
 {
  return true;
 }
else
 {
//alert("message");
 return false;
 }
}
onKeyPress1 = (e) => {
//onKeyPress1(e){
const { name, value } = e.target; 
let formErrors = {};    
//let formIsValid = true;    
this.setState({ [name]: value });
if(!(/^[A-Za-z ]+$/.test(value) ) ){
// formIsValid = false;
     formErrors["nameErr"] = "School name must be valid.";
}else{
    this.setState({ [name]: value }); 
}
}
  handleFormValidation() {    
    const { 
    name, 
    mobile,
    email,
    message, } = this.state;    
    let formErrors = {};    
    let formIsValid = true;    
  
    //Student name     
    if (!name) {    
        formIsValid = false;    
        formErrors["nameErr"] = "Please Enter name.";    
    } else if( !(/^[A-Za-z ]+$/.test(name) ) ){
        formIsValid = false; 
        formErrors["nameErr"] = "Name must be valid.";
    }
    //Email    
    if (!email) {    
        formIsValid = false;    
        formErrors["emailErr"] = "Please Enter Email id.";    
    }    
    else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {    

        formIsValid = false;    
        formErrors["emailErr"] = "Please Enter Invalid email id.";    
    }    
    if (!mobile) {    
      formIsValid = false;    
      formErrors["mobileErr"] = "Please Enter Mobile Number.";    
  }    
  else {    
      var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/;    
      if (!mobPattern.test(mobile)) {    
          formIsValid = false;    
          formErrors["mobileErr"] = "Please Enter Valid Mobile Number.";    
      }    
  }
    if (!message) {    
        formIsValid = false;    
        formErrors["messageErr"] = "Please Enter Address.";    
    }
    
    let user_captcha_value = document.getElementById('user_captcha_input').value;
    if (!user_captcha_value) {    
        formIsValid = false;    
        formErrors["captchaErr"] = "Please Enter Captcha.";    
    }else if (validateCaptcha(user_captcha_value)===true) { 
    }else{  
        formIsValid = false;    
        formErrors["captchaErr"] = "Please Enter Valid Captcha.";
    }
    this.setState({ formErrors: formErrors });    
    return formIsValid;    
  }
checkCharcterOnly = (e) => { 
    const { name, value } = e.target; 
    if((/^[A-Za-z ]+$/.test(value) ) ){
        this.setState({ [name]: value }); 
        }
}  
checkNumberOnly = (e) => {   
    const { name, value } = e.target; 
    if((/^[0-9]+$/.test(value) ) ){
        this.setState({ [name]: value });
    }
}
handleChange = (e) => { 
    const { name, value } = e.target;    
    this.setState({ [name]: value });    
} 
handleSubmit = (e) => {    
    e.preventDefault();    

    if (this.handleFormValidation()) {    
        //alert('You have been successfully registered.')
        this.sendContactDetails();
        //console.log(this.initialState);
    }    
}

sendContactDetails = () => {
    axios
      .post(
        //"http://localhost/webservices/sendContact.php",
        "https://schooleasyerp.com/webservices/sendContact.php",
        this.state
      )
      .then((response) => {
        if (response.status === 200) {
            console.log(response);
           this.setState(this.initialState);
            alert(response.data.message);  
        }
      });
  };

  componentDidMount() {
    loadCaptchaEnginge(6); 
  } 
  render() {
    const { nameErr, mobileErr, emailErr, messageErr,captchaErr } = this.state.formErrors;
    var sectionStyle = {backgroundImage: "url(" + { client_img } + ")"
  };
    return (
      <div>
      	<Header/>
        <div id="intro-example" class="p-5 text-center bg-image" style={{backgroundImage: "url(" + client_img + ")"}}>
      <div class="mask">
        <div class="d-flex justify-content-center align-items-center h-100">
          <div class="text-white">
            <h1>Contact Us</h1>
          </div>
        </div>
      </div>
    </div>
       
<div class="container py-5">
  <section class="contact_information">
    <h3 class="text-center pb-5 font-weight-bold title">Business Queries</h3>
    <div class="row align-items-center">
      <div class="col-xl-6 col-lg-7 mb-4 mb-lg-0">
        <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.901459672608!2d75.81542821504415!3d26.87487168314391!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db67d5f541133%3A0xa0a719cacdd510f2!2sRajasthan%20knowledge%20Corporation%20Limited!5e0!3m2!1sen!2sin!4v1640332409383!5m2!1sen!2sin"
        class="contact-map rounded-6 shadow-4-strong w-100" allowfullscreen="" loading="lazy" ></iframe>
      </div>
      <div class="col-xl-6 col-lg-5">
        <div class="row gx-lg-5">
          <div class="col-xl-12 col-lg-12 col-md-6 mb-xl-5 mb-lg-4 mb-md-5 mb-4">
            <div class="d-flex align-items-start">
              <div class="flex-shrink-0">
                <div class="p-3 bg-primary rounded-4 shadow-2-strong">
                  <i class="fas fa-map-signs fa-lg text-white fa-fw"></i>
                </div>
              </div>
              <div class="flex-grow-1 ms-4">
                <p class="fw-bold mb-1">Complete Address</p>
                <a href="https://goo.gl/maps/zhDNDDSzX8fdzCYq7" target="_blank" class="text-muted mb-0">Rajasthan knowledge Corporation Limited 7-A behind R.T.O, Jhalana Intitutional Area, Jaipur Rajasthan  302004</a>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-lg-12 col-md-6 mb-xl-5 mb-lg-4 mb-md-0 mb-4">
            <div class="d-flex align-items-start">
              <div class="flex-shrink-0">
                <div class="p-3 bg-primary rounded-4 shadow-2-strong">
                  <i class="fas fa-phone-alt fa-lg text-white fa-fw"></i>
                </div>
              </div>
              <div class="flex-grow-1 ms-4">
                <p class="fw-bold mb-1">Contact Number</p>
                <a href="tel:0141-5159700" class="text-muted mb-0"> 0141-5159700</a>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-lg-12 col-md-6 mb-xl-5 mb-0">
            <div class="d-flex align-items-start">
              <div class="flex-shrink-0">
                <div class="p-3 bg-primary rounded-4 shadow-2-strong">
                  <i class="fas fa-envelope-open-text fa-lg text-white fa-fw"></i>
                </div>
              </div>
              <div class="flex-grow-1 ms-4">
                <p class="fw-bold mb-1">Email Support</p>
                <a href="mailto:bishalc@rkcl.in" target="_blank" class="text-muted mb-0">bishalc@rkcl.in</a>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-12 col-md-6 mb-xl-5 mb-0">
            <div class="d-flex align-items-start">
            {/*  <div class="flex-shrink-0">
                <div class="p-3 bg-primary rounded-4 shadow-2-strong">
                  <i class="fab fa-whatsapp fa-lg text-white fa-fw"></i>
                </div>
              </div> 
              <div class="flex-grow-1 ms-4">
                <p class="fw-bold mb-1">Whatsapp</p>
                <a href="https://api.whatsapp.com/send?phone=919571237334&text=Hello%20RKCLs%20SchoolEasy%20Team" target="_blank" class="text-muted mb-0">9571237334</a>
              </div>*/}
            </div>
    </div>
    <div class="col-xl-12 col-lg-12 col-md-6 mb-xl-5 mb-0">
            <div class="d-flex align-items-start"></div>
    </div>
        </div>
      </div>
    </div>
  </section>
  <section class="contact_information">
    <h3 class="text-center pb-5 font-weight-bold title">Customer Care (Tech Support)</h3>
    <div class="row align-items-center">
      
      <div class="col-xl-12 col-lg-5">
        <div class="row gx-lg-5">
          <p>Further for any assistance and help you may get in touch with our support team from Monday to Friday 9:30 AM to 6:00 PM</p>
          
          <div class="col-xl-12 col-lg-12 col-md-12 mb-xl-5 mb-lg-4 mb-md-0 mb-4">
            
            <div class="d-flex align-items-start">
              <div class="flex-shrink-0">
                <div class="p-3 bg-primary rounded-4 shadow-2-strong">
                  <i class="fas fa-phone-alt fa-lg text-white fa-fw"></i>
                </div>
              </div>
              <div class="flex-grow-1 ms-3">
                <p class="fw-bold mb-1">Contact Number</p>
                <a href="tel:01415159700" class="text-muted mb-0"> Tel: 0141 - 5159700 <br/>(Ext: 885/888/852/887)</a>
              </div>
              {/*<div class="flex-grow-1 ms-3">
                <p class="fw-bold mb-1">Timing:</p>
                <a href="tel:01415159700" class="text-muted mb-0"> Monday to Friday <br/>9:30 AM to 6 PM </a>
              </div>*/}
              <div class="flex-shrink-0">
                <div class="p-3 bg-primary rounded-4 shadow-2-strong">
                <i class="fas fa-envelope-open-text fa-lg text-white fa-fw"></i>
                </div>
              </div>
              <div class="flex-grow-1 ms-3">
              
                <p class="fw-bold mb-1">Tech support emails -</p>
                
                <a href="mailto:rahulg@rkcl.in" target="_blank" class="text-muted mb-0">rahulg@rkcl.in</a><br/>
                <a href="mailto:careschooleasy@rkcl.in" target="_blank" class="text-muted mb-0">careschooleasy@rkcl.in</a>
              </div>
              <div class="flex-shrink-0">
                <div class="p-3 bg-primary rounded-4 shadow-2-strong">
                <i class="fas fa-envelope-open-text fa-lg text-white fa-fw"></i>
                </div>
              </div>
              <div class="flex-grow-1 ms-3">
                <p class="fw-bold mb-1">Billing/Training - Account Support Email -</p>
                <a href="mailto:bishalc@rkcl.in" target="_blank" class="text-muted mb-0">bishalc@rkcl.in</a><br/>
                
              </div>
              <div class="flex-shrink-0">
                <div class="p-3 bg-primary rounded-4 shadow-2-strong">
                  <i class="fas fa-phone-alt fa-lg text-white fa-fw"></i>
                </div>
              </div>
              <div class="flex-grow-1 ms-3">
                <p class="fw-bold mb-1">Account Support contacts</p>
                <a href="tel:9352363539" class="text-muted mb-0"> Tel: 9649900913</a><br/>
                
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </section>


  <div class="container pb-5">
  <section class="text-left">
  <h1>Get in touch</h1>
  <form onSubmit={this.handleSubmit}>
      <div class="row align-items-left">
        <div class="col-lg-5 animation slide-in-left" >
          <div class="row">
          <label class="form-label" for="form3Example1" >Name</label>
          <input type="text" 
          id="form3Example1" 
          name="name" 
          onChange={event=> this.checkCharcterOnly(event)}
          value={this.state.name} 
          maxlength="35"
          class="form-control"/>
          {nameErr && <div style={{ color: "red", paddingLeft: 0, textAlign: "left" }}>{nameErr}</div>}
          </div>
          <div class="row">
          <label class="form-label" for="form3Example2">Email</label>
            <input name="email"
              type="text"
              className="form-control"
              id="email"
              value={this.state.email}
              onChange={event=> this.handleChange(event)}
              maxlength="35" 
               class="form-control"/>
            {emailErr && <div style={{ color: "red", paddingLeft: 0, textAlign: "left" }}>{emailErr}</div>}
          <div class="form-notch"><div class="form-notch-leading"></div><div class="form-notch-middle" ></div><div class="form-notch-trailing"></div></div></div>
          <div class="row">
          <label class="form-label" for="form3Example4">Mobile Number</label>
            <input type="text" 
            name="mobile"
              id="mobile" 
              className="form-control"
              
              value={this.state.mobile}
              onChange={event=> this.checkNumberOnly(event)}
              aria-describedby="inputGroupPrepend"
              maxLength="10"
             class="form-control" />
            {mobileErr && <div style={{ color: "red", paddingLeft: 0, textAlign: "left" }}>{mobileErr}</div>}
          </div>
          <div class="row">
          <label class="form-label" for="form4Example3">Message</label>
            <textarea name="message"
            value={this.state.message}
            onChange={event=> this.handleChange(event)} 
            class="form-control" id="form4Example3" rows="4"></textarea>
            {messageErr && <div style={{ color: "red", paddingLeft: 0, textAlign: "left" }}>{messageErr}</div>}
          </div>
        <div class="row">
        <div className="col-md-6" style={{ paddingLeft: 0 }}>
          <label class="form-label" for="form4Example3">&nbsp;</label>
        <input className="form-control"  placeholder="Enter Captcha" 
        id="user_captcha_input" 
        name="user_captcha_input" 
        type="text"
        value={this.state.user_captcha_input}
        onChange={event=> this.handleChange(event)}
        maxlength="6" />{captchaErr &&    
        <div style={{ color: "red", paddingLeft: 0, textAlign: "left" }}>{captchaErr}</div>    
                   }
        </div><div className="col-md-6"><div className="form-outline1">
            <LoadCanvasTemplate />
         </div>
         </div>
          </div>
          <div class="row text-center pb-3"><label for="validationCustom03" className="form-label"></label>
            <button type="submit" class="btn btn-primary btn-block mb-5 mb-md-0 col-md-2"> Send </button>
          </div>
        </div>
        <div class="col-lg-7 d-none d-lg-block animation slide-in-right" >
          <img src={contact_img} class="img-fluid" alt="smaple image"/>
        </div>
      </div>
    </form>
  </section>
</div>

</div>
        <Footer/>

      </div>
    );
  }
}

export default App
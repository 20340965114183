import React, { Component } from 'react'
import ReactGA from 'react-ga';

import { Modal,ModalHeader,ModalBody } from "reactstrap";
import axios from "axios";
import footerLogo from '../Assets/img/Asset-39.png'
import androidLogo from '../Assets/img/Asset-33.png'
import qrcodeapp from '../Assets/img/qr-code-app.png'
import MultiPlayer from "./MultiPlayer";
export class Footer extends Component {
  constructor(props) {    
    super(props); 
    this.state = {    
        //name: "",
        visitors:0,
    //formErrors: {}    
    };    
    this.initialState = this.state;    
  } 
  componentDidMount() {
    this.getVisitorCount();
  } 
  getVisitorCount() { 
    //axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    //"http://localhost/webservices/getVisitor.php"
    //"https://schooleasyerp.com/webservices/getVisitor.php"
    axios.get(
      "https://schooleasyerp.com/webservices/getVisitor.php"
      ).then((response) => {
      //console.log(response.data);
        if (response.status === 200) {
        this.setState({
          visitors: response.data ? response.data.result : [],
        });
        }
        //console.log(this.state.visitors);
        if (
        response.data.status === "failed" &&
        response.data.success === false
        ) {
        this.setState({
            noDataFound: response.data.message,
        });
        }
    });
    } 
    
  render() {
    
  let visitors = this.state.visitors;
  //console.log(visitors);
  ReactGA.initialize('UA-221438034-1');
    return (
      <div>
        <footer className="footer">
          <div className="footer-top py-4">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-xl-4 col-lg-4 col-md-6 footer-links text-lg-start text-center">
                  <img src={footerLogo} alt="" className="img-fluid" />
                  <ul className="pt-4">
                    <li>
                      <i className="fas fa-map-signs"></i> 
                      <a href="https://goo.gl/maps/zhDNDDSzX8fdzCYq7" target="_blank" > Rajasthan Knowledge Corporation Limited 7-A behind
                        R.T.O, Jhalana Intitutional Area, Jaipur Rajasthan
                        302004
                      </a>
                    </li>
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <a href="tel:0141-5159700"> 0141-5159700</a>
                    </li>
                    <li>
                      <i className="fas fa-envelope-open-text"></i>
                      <a href="mailto:bishalc@rkcl.in" target="_blank"> bishalc@rkcl.in
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 footer-links text-lg-start text-center">
                  <h4>Other Important Links</h4>
                  <ul className="pt-3">
                    <li>
                      <a href="https://www.rkcl.in">About us</a>
                    </li>
                    <li>
                      <a href="/contact">Email & SMS</a>
                    </li>
                    
                    <li>
                      <a href="/privacypolicy">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="#">Visitors: </a>
                    </li>
                    <li>
                      <span className="visitor_count">{visitors}</span>
                      
                      
                    </li>
                  </ul>
                </div>
                <div className="col-xl-4 col-lg-5 col-md-12 footer-newsletter">
                  <h4>JOIN US ON</h4>
                  <ul className="social-network social-circle pt-3">
                    <li>
                      <a href="https://www.facebook.com/RKCLINDIA/" className="icoFacebook" title="Facebook">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    
                    <li>
                      <a href="https://www.youtube.com/RKCLINDIA" className="icoyoutube" title="Youtube">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    
                    <li>
                      <a href="https://api.whatsapp.com/send?phone=919571237334&text=Hello%20RKCLs%20SchoolEasy%20Team" className="icowhatsapp" title="WhatsApp">
                        <i className="fab fa-whatsapp"></i>
                      </a>
                    </li>
                  </ul>
                  <h5 className="py-1">DOWNLOAD OUR APP</h5>
                  <h6 className="pb-2">SchoolEasy</h6>
                  <a href="https://play.google.com/store/apps/details?id=com.rkcl.schoolmanageent&hl
"><img className="img-fluid googleplay" src={androidLogo} /></a> 
                  <img className="img-fluid1" src={qrcodeapp} onClick={this.props.toggleNewQRModal}/>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid p-0">
            <div className="copyright p-2 text-center">
              Copyrights &copy; {new Date().getFullYear()} SchoolEasy. All Rights Reserved.
            </div>
          </div>

        <Modal isOpen={this.props.newQRModal} toggle={this.props.toggleNewQRModal} className="modal-dialog modal-dialog-centered">
          <ModalHeader toggle={this.props.toggleNewQRModal}>
          Scan QR Code for SchoolEasy APP
          </ModalHeader>
          <ModalBody>
          <img className="img-fluid" src={qrcodeapp} />
          </ModalBody>
          
        </Modal>
        </footer>
      </div>
    );
  }
}

export default Footer;

import React, { Component } from "react"

//import '../Assets/css/mdb.min.css';
import "../Assets/css/style.css";
import "../Assets/css/responsive.css";
//import "../Assets/js/mdb.min.js";
//import "../Assets/js/jquery-2.2.4.min.js";
//import "../Assets/js/jquery.bxslider.js";

import Header from './Header';
//import PrivacyPolicy from './privacypolicy';
import Footer from './Footer';


export class PrivacyPolicy extends Component {


    render() {

        return (
            <div>
                <Header />
                <div class="container pb-5">
                    <section class="guest_opinion pt-5">
                        <h3 class="text-center pb-4 font-weight-bold title"> About SchoolEasy </h3>
                        <p>Since its inception in 2008, we at RKCL have set many milestones in the fields of Digital Literacy, Skill Development, e-Governance and e-Empowerment projects. RKCL has trained more than 75 lakh learners till date across various sectors. RKCL has been the pioneer in spreading digital literacy in Rajasthan leveraging its ever-expanding network of 6500+ IT Gyan Kendra's across all the districts & tehsils of Rajasthan.</p>
                        <p>RKCL brand has been playing a pivotal role in bridging the digital divide, inspiring youth on new age skill building & advocating digital transformation revolution in Rajasthan. RKCL has always been committed to leveraging technology to drive innovation and efficiency. Our proven track record of delivering cutting-edge training & skilling solutions have solidified our reputation as a trusted partner in the technology industry.</p>
                        <p>Hence recognizing the pivotal role that schools play in shaping the future, we at RKCL identified a significant digital product development opportunity to contribute to the educational landscape. Schools today face increasing challenges in managing complex operations, ensuring academic excellence, and enhancing student experiences. These challenges often result in inefficiencies and a fragmented approach to education. Driven by our passion for education and our expertise in technology, we embarked on a journey to develop a comprehensive School ERP software. </p>
                        <p>With a strong brand legacy & in-house technical prowess, RKCL developed & launched a fully cloud-based School Automation ERP solution branded as “SchoolEasy” in the year 2020 & has been successfully running it since then with a vision to automate/digitize all schools of Rajasthan & other parts of India with best of features at most affordable rates.</p>
                        <p>RKCLs SchoolEasy is a robust, cloud-native platform meticulously engineered to streamline and optimize the multifaceted operations of educational institutions. By leveraging advanced data management, workflow automation, and role-based access control mechanisms, we empower schools to transition from traditional, paper-intensive processes to a digitally transformed ecosystem. By integrating all school functions into a single platform, it ensures that schools run efficiently, enabling educators to focus on their core mission of providing quality education.</p>
                    </section>
                    
                </div>


                <Footer />

            </div>
        );
    }
}

export default PrivacyPolicy
import React, { Component } from "react";
import { Modal,ModalHeader,ModalBody } from "reactstrap";
import "../Assets/css/style.css";
import "../Assets/css/responsive.css";
import Header from './Header';
import Footer from './Footer';
import paymentqrcode from '../Assets/img/rkcl_payment_qr.jpg'
export class PaymentStatic extends Component {
    render() {
        return (
            <div>
                <Header/>
                <div class="container pb-5">
                    <section class="guest_opinion pt-5">
                        <h3 class="text-center pb-4 font-weight-bold title">Payment</h3>
                        <p>
                        As per SchoolEasy plan chosen, kindly pay necessary fees via NEFT/IMPS mode directly to RKCL Bank account as mentioned below -
                        </p>
                        <div class="pt-3">
                        <p><strong>Account Holder Name -</strong> Rajasthan Knowledge Corporation Ltd.</p>
                        <p><strong>Account Number -</strong> 911020042604401</p>
                        <p><strong>IFSC Code - </strong>UTIB0000031</p>
                        <p><strong>Bank Name - </strong>Axis Bank, Tilak Nagar, Jaipur</p>
                        </div>
                        <div class="pt-3">
                        <p>Once payment is made, share the Payment Details (Date, Transaction Reference Number, etc) via email to - <a href="mailto:bishalc@rkcl.in" target="_blank">bishalc@rkcl.in</a> & <a href="mailto:vinodj@rkcl.in" target="_blank">vinodj@rkcl.in</a> along with complete details of School Name & School Address</p>
                        </div>
                        <div class="pt-3 text-center">
                        <h5>UPI ID of RKCL - mab.037212000310193@axisbank</h5>
                        <h5>UPI QR Code of RKCL</h5>
                        <img className="img-fluid" src={paymentqrcode} onClick={this.props.togglePaymentQRModal}/>
                        </div>
                        <div class="pt-3"><br></br>
                        </div>
                    </section>
                </div>
        <Modal isOpen={this.props.newQRModal} toggle={this.props.togglePaymentQRModal} className="modal-dialog modal-dialog-centered">
          <ModalHeader toggle={this.props.togglePaymentQRModal}>
          UPI QR Code of RKCL
          </ModalHeader>
          <ModalBody>
          <img className="img-fluid" src={paymentqrcode} />
          </ModalBody>
          
        </Modal>
                <Footer />
            </div>
        );
    }
}

export default PaymentStatic
import React, { PureComponent } from "react";

//import { Table, Button } from "reactstrap";

//import axios from "axios";
import { loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha';

//import '../Assets/css/mdb.min.css';
//import "../Assets/css/style.css";
//import "../Assets/css/animation.css";
//import "../Assets/css/responsive.css";
//import "../Assets/js/mdb.min.js";
//import "../Assets/js/jquery-2.2.4.min.js";
//import "../Assets/js/jquery.bxslider.js";
//import "../Assets/js/custom.js";



import Header from '../components/Header';
import Slider from '../components/Slider';
import Footer from '../components/Footer';
import Middle from '../components/Middle';
import Benifit from '../components/NewBenifit';
//import TeacherList from '../components/TeacherList';
import SchoolEasy from '../components/SchoolEasy';
import About from '../components/about';
import Client from '../components/Client';
import MobieApp from '../components/MobieApp';
//import DemoRequestForm from '../components/DemoRequestForm';

export class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      /*newStudentData: {
              // schoolname: "",
              // board:"",
              // state:"",
              // district:"",
              // schoolweb:"",
              // count:"",
              // contact:"",
              // designation:"",
              // mobile:"",
              // email:"",
              // date:"",
              // Itgk_code:"",
              // Itgk_name:"",
              // address:"",
      },*/
      visitors:0,
      //hasNameError: true,
      newQRModal: false,
      
    };
    //this.onChangeAddStudentHandler = this.onChangeAddStudentHandler.bind(this);
  }

/*onChangeAddStudentHandler = (e) => {
  //console.log(e);
    let { newStudentData } = this.state;
    newStudentData[e.target.name] = e.target.value;
    this.setState({ newStudentData });
    //console.log(newStudentData);
};

validateForm1 = (e) => {
  e.preventDefault();
let user_captcha_value = document.getElementById('user_captcha_input').value;
 if (validateCaptcha(user_captcha_value)===true) {
      // action
      if (this.handleFormValidation()) { 
        alert('You have been successfully registered.');
        this.addStudent();
      }else{
        //alert("All validated!");
        //this.addStudent(); // call api for 
      }
    } else {
      alert('Captcha Does Not Match');
  }
  
};*/
  
  componentDidMount() {
    //this.getDemoRequestData();
    //loadCaptchaEnginge(6); 
    //this.getVisitorCount();
  } 
  /*getDemoRequestData() {
    axios.get("https://schooleasyerp.com/webservices/getSchoolList.php").then((response) => {
      //console.log(response.data);
        if (response.status === 200) {
        this.setState({
            students: response.data ? response.data : [],
        });
        }
        //console.log(this.state);
        if (
        response.data.status === "failed" &&
        response.data.success === false
        ) {
        this.setState({
            noDataFound: response.data.message,
        });
        }
    });
    }*/
    
    toggleNewQRModal = () => {
      this.setState({
        newQRModal: !this.state.newQRModal,
      });
    };
  render() {
    //const { newStudentData,students} = this.state;
    const { students} = this.state;
    return (
      <div>
      	<Header/>
        <Slider/>
        <Middle
        students={students}/>
        <Benifit/>
        
        <SchoolEasy/>
        <Client
        students={students}/>
        <MobieApp/>
        
        <Footer
         toggleNewQRModal={this.toggleNewQRModal}
         newQRModal={this.state.newQRModal}/>
        
      </div>
    );
  }
}

export default App
import React, { Component } from 'react'

import Asset23 from '../Assets/img/Asset-23.png'
import Asset24 from '../Assets/img/Asset-24.png'
import Asset25 from '../Assets/img/Asset-25.png'
import Asset26 from '../Assets/img/Asset-26.png'
import Asset27 from '../Assets/img/Asset-27.png'
import Asset33 from '../Assets/img/Asset-33.png'

export class MobieApp extends Component {
    render() {
        return (
            <div>
                <div className="mobile_app">
                    <div className="container">
                        <div className="row pt-4">
                            <div className="col-xl-6 col-lg-5 col-md-5 col-sm-5 text-center mobile_for_android order-2 order-sm-1" data-mdb-toggle="animation"
                                data-mdb-animation-start="onScroll" data-mdb-animation-on-scroll="repeat"
                                data-mdb-animation="slide-in-left">
                                
                                    <img src={Asset27} className="img-fluid" />
                                    
                            </div>
                            <div className="col-xl-6 col-lg-7 col-md-7 col-sm-7 align-self-center order-1 order-sm-2" data-mdb-toggle="animation"
                                data-mdb-animation-start="onScroll" data-mdb-animation-on-scroll="repeat"
                                data-mdb-animation="slide-in-right">
                                
                                <h3>SchoolEasy Mobile Apps</h3>
                                <h4>A self-serve portal for your stakeholders </h4>
                                <div className="row py-3">
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 text-center">
                                        <figure>
                                            <a href="javascript:void()"><img src={Asset23} className="img-fluid"/></a>
                                            <figcaption>Mobile App for <span>Parents</span></figcaption>
                                        </figure>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 text-center">
                                        <figure>
                                            <a href="javascript:void()"><img src={Asset24} className="img-fluid"/></a>
                                            <figcaption>Mobile App for <span>Teachers</span></figcaption>
                                        </figure>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 text-center">
                                        <figure>
                                            <a href="javascript:void()"><img src={Asset25} className="img-fluid"/></a>
                                            <figcaption>Mobile App for <span>Principals</span></figcaption>
                                        </figure>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 text-center">
                                        <figure>
                                            <a href="javascript:void()"><img src={Asset26} className="img-fluid"/></a>
                                            <figcaption>Mobile App for <span>Management</span></figcaption>
                                        </figure>
                                    </div>
                                </div>
                                <div className="andriod_app">
                                    <h5>Available On <a href="https://play.google.com/store/apps/details?id=com.rkcl.schoolmanageent&hl
"><img src={Asset33} className="img-fluid googleplay"/></a></h5>
                                </div>
                            

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MobieApp

import React, { Component } from "react"

//import '../Assets/css/mdb.min.css';
import "../Assets/css/style.css";
import "../Assets/css/responsive.css";
//import "../Assets/js/mdb.min.js";
//import "../Assets/js/jquery-2.2.4.min.js";
//import "../Assets/js/jquery.bxslider.js";

import Header from './Header';
//import PrivacyPolicy from './privacypolicy';
import Footer from './Footer';
export class PrivacyPolicy extends Component {
    render() {
        return (
            <div>
                <Header />
                <div class="container pb-5">
                    <section class="guest_opinion pt-5">
                        <h3 class="text-center pb-4 font-weight-bold title">Privacy Policy</h3>
                        <p>
                        This Privacy Policy governs the manner in which RKCL collects, uses, maintains and discloses information collected from users (each, a "User") of the www.schooleasyerp.com website ("Site"). This privacy policy applies to the Site and all products and services offered by RKCL.</p>

                        <div class="pt-3">
                        <h5>Personal identification information</h5>
                        <p>We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site, place an order, subscribe to the newsletter, fill out a form, and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, phone number. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personal identification information, except that it may prevent them from engaging in certain Site related activities.</p>
                        </div>

                        <div class="pt-3">
                        <h5>Non-personal identification information</h5>
                        <p>We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service providers utilized and other similar information.</p>
                        </div>

                        <div class="pt-3">
                        <h5>Web browser cookies</h5>
                        <p>Our Site may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.</p>
                        </div>
                            
                        <div class="pt-3">
                        <h5>How we use collected information</h5>
                        <p>RKCL may collect and use Users personal information for the following purposes:</p>
                        <dl>
                            <dt>To improve customer service</dt>
                            <dd>Information you provide helps us respond to your customer service requests and support needs more efficiently.</dd>
                            <dt>To personalize user experience</dt>
                            <dd>We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.</dd>
                            <dt>To improve our Site</dt>
                            <dd>We may use feedback you provide to improve our products and services.</dd>
                            <dt>To process payments</dt>
                            <dd>We may use the information Users provide about themselves when placing an order only to provide service to that order. We do not share this information with outside parties except to the extent necessary to provide the service.</dd>
                            <dt>To send periodic emails</dt>
                            <dd>We may use the email address to send User information and updates pertaining to their order. It may also be used to respond to their inquiries, questions, and/or other requests. If User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email or User may contact us via our Site.</dd>
                            
                        </dl>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        );
    }
}

export default PrivacyPolicy